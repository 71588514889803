import React, {useEffect, useState} from 'react'
import {useLocation, useHistory, useParams} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { transL, transS } from '../../../../services/helpers/lang'
import { colors, fontSize } from '../../../../components/colors'
import ResponsiveMenu from './ResponsiveMenu';
import { unitHelper } from '../../views/unitList/unitsFuncs';
import ShowTinyStatus from '../../views/unitList/ShowTinyStatus';
import DocumentMenuTitle from './DocumentMenuTitle';

export const TopDocMenu = () => {
  const 
    {isDocument, unitsStruc, luserId} = useSelector(state => state.unitReducer),
    {user} = useSelector(state => state.userReducer),
    dispatch = useDispatch(),

    [items, setItems] = useState([]),

    Scaffold = () => <div style={{
      height: 30,
      width: 1,
      margin: '10px 0',
      backgroundColor: colors.lightGray,
    }}></div>,

    fake = false

    useEffect(() => {
      const items = [
        <DocumentMenuTitle />,
        <Scaffold key="1" />
      ], 
      {iAmInternal, canEditTeams,
        isInternal, hasExternals, inGroup, hasParams, docProps} 
        = unitHelper(isDocument, luserId),
			{checkApprove, status} = docProps   
      
      items.push(<ShowTinyStatus status={status} />)

      items.push('la-la-la')
           
      //approval & exclamation = iAmInternal && checkApprove

      setItems(items)
    }, [isDocument])


  return <ResponsiveMenu items={items}/>
}
