import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { transS } from '../../../../services/helpers/lang';
import { Icon_ArrowDown, Icon_CheckBlank, Icon_Checked, Icon_Doc, Icon_Folder } from '../../../../components/svglib/svglib';
import { TreeSimple } from '../../../../components/TreeNode';
import { colors, fontSize } from '../../../../components/colors';

let unitSelected, callback, openFolders
const unitId = unit => unit ? "sel"+unit.loc_id : "sel0"
const selectItem = (unit) => {
  if (unit?.externals) return
  document.getElementById(unitId(unitSelected)).dataset.filterset = "0"
  unitSelected = unit
  document.getElementById(unitId(unitSelected)).dataset.filterset = "1"
  callback(unit)
}
const icons = {
  arrow_down: <Icon_ArrowDown />,
  arrow_up: <Icon_ArrowDown flip="1" />,  
}

export const SelectLocation = ({unvisible, unit, action}) => {
  const {unitsList, unitsTree, unitsStruc} = 
    useSelector(state => state.unitReducer),
    list = unitsTree.filter(u => (!u.ancestor && u.mark !== 'p')),
    getChildren = unit => 
      !unit.folders?.length ? [] :
      unit.folders.map(x => unitsList.get(x))
    openFolders = unitsStruc.openFolders
    unitSelected = unit
    callback = action
    
  
  return unvisible ? false : <div>
    <div className='h2'>{transS('Where to save')}</div>
    <div style={styleLine} id="sel0"
      data-filterset={unit ? "0" : "1"}
      onClick={() => selectItem(null)} >
      <span>
        <span data-check="1"><Icon_Checked /></span>
        <span data-check="0"><Icon_CheckBlank /></span>
      </span>
      <Icon_Doc />
      <span>{transS('As New Document')}</span>
    </div>
    <div className='h3' style={{marginTop:40}}>{transS('All Locations')}</div>
    {list.map(el => <TreeSimple
        key={el.loc_id}
        unit={el}
        ShowElement={ShowListElement}
        getChildren={getChildren}
      />)}
  </div>
}

const styleLine = {
  display: 'flex',
  gap: 20,
  marginTop: 20,
}, styleExtension = {
  fontSize: fontSize.tip,
  color: colors.gray,
}

const ShowListElement = ({unit}) => {
  const isDoc = !!(unit.d_created || unit.orig),
    contentType = unit.contentType,
    isFolder = !isDoc && !contentType,
    hasChildren = !!unit.folders?.length,
    unitRef = useRef()

  const toggleArrow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (unitRef.current.dataset.closed != 1)     
        unitRef.current.dataset.closed = "1"
    else 
        unitRef.current.dataset.closed = ""
    }
  
  let visibleTitle = unit.title, visibleExtension = ''
  if (contentType) {
      const name = visibleTitle.split('.');
      visibleExtension = name.pop().toUpperCase();
      visibleTitle = name.join('.');
  }

  return <div style={styleLine} id={"sel"+unit.loc_id} ref={unitRef}
    data-closed={openFolders.has(unit.loc_id) ? "" : "1"}
    data-filterset={unit !== unitSelected ? "0" : "1"}
    onClick={() => selectItem(unit)} >
  {!!unit.level && <span style={{width:unit.level*40 - 20}}></span> }
  <span>
    <span data-check="1"><Icon_Checked /></span>
    <span data-check="0"><Icon_CheckBlank /></span>
  </span>
  {isDoc ? <Icon_Doc />
    : isFolder ? <Icon_Folder />
    : <span style={styleExtension}>{visibleExtension}</span>
  }
  <span>{visibleTitle}</span>
  {hasChildren 
    && <div 
      onClick={ e => toggleArrow(e)}          
    >
      <span data-on-open="1">{icons.arrow_up}</span>
      <span data-on-close="1">{icons.arrow_down}</span>
  </div>}
</div>
}


