import React, { useEffect, useRef, useState, useCallback, 
    createContext, useContext, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom'
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL, transS } from '../../../../services/helpers/lang';
import { rights } from '../../../../../configs/appConfig';
import { Tree } from './Tree';
import ShowElement from './ShowElement';
import store from '../../../../services/store';
import { unitConstants } from '../../../../services/reducers/unitReducer';
import { Icon_ArrowDown, Icon_Doc, Icon_Folder, Icon_Mouse, 
    Icon_Pin, 
    Icon_Pinned, 
    Icon_TickCircle } from '../../../../components/svglib/svglib';
import { TreeNode } from '../../../../components/TreeNode';
import { colors } from '../../../../components/colors';
import Spinner from '../../../../components/spinner/Spinner';
import FilterDocs from './FilterDocs';

const ShowElementContext = createContext(),
  elementContextProvider = ({ children, ...props }) => {
    const context = props
    return <ShowElementContext.Provider value={props}>{children}</ShowElementContext.Provider>;
  };

const icons = {
  arrow_down: <Icon_ArrowDown />,
  arrow_up: <Icon_ArrowDown flip="1" />,
  mouse: <Icon_Mouse />,
  tick_ok: <Icon_TickCircle size="10" back={colors.primary} />,
  tick_approve: <Icon_TickCircle size="10" back="none" 
    border={colors.approve} front={colors.approve} />, 
  tick_semi_approve: <Icon_TickCircle size="10" back="none" 
    border={colors.gray} front={colors.gray} />, 
  folder: <Icon_Folder />,
  doc: <Icon_Doc />,
  pinned: <Icon_Pinned color={colors.darkGray} />
}

export const showContext = () => {
    return useContext(ShowElementContext)
}

const getInitialList = (tree) => {  
  return tree.filter(el => !el.ancestor)
}

const Units = () => {      

    const {unitsTree, unitsStruc, unitsList} = 
        useSelector(state => state.unitReducer)
    const firstWidth = unitsStruc.maxLevel*40 + (unitsStruc.maxLevel ? 200 : 240),
      firstColumnStyle = {width: `${firstWidth}px`}
    let openTeamsCallback = {call: null}, contextMenuCallback = {call: null}
    const [list, setList] = useState([]),
      [actual, setActual] = useState(undefined)
    const {pathname} = useLocation(), loc_id = pathname.split('/')[2],
      topElement = unitsList.get(loc_id)
    const history = useHistory(),
      contRef = useRef()
      


    const goHome = (loc_id) => {
      if (!loc_id) {
        history.push('/units')
      } else {
        history.push(`/units/${loc_id}`)
      }
    }      

    useEffect(() => {
      const list = topElement ? [topElement] : 
        getInitialList(unitsTree)
      setList(list)
      setActual(loc_id)
      if (!topElement) {
        unitsStruc.listCallback = (func) => setList(func)
      }
      return () => {
        if (!topElement) {
          unitsStruc.listCallback = null
        }
      }
    }, [loc_id, unitsTree])
    

    const getChildren = useCallback(unit => 
      !unit.folders?.length ? [] :
      unit.folders.map(x => unitsList.get(x)),[unitsTree]
    )

    const fillFilterCSS = filter => {
      const container = contRef.current,
        {test, inputChanged, finFilter, approvalFilter} = filter,
        result = ['','p','f'][finFilter]+['','a'][approvalFilter]+(test ? 's' : '')
      if (inputChanged) {
        document.querySelectorAll(`[data-filters]`).forEach(el =>
          {delete el.dataset.filters })
        if (test) {
          const searchString = test.toLowerCase()
          unitsList.forEach(u => {
            if (!u.title.toLowerCase().includes(searchString)) {
              const el = document.querySelector(`[data-lockid="${u.loc_id}"]`)
              if (el)
                el.dataset.filters = "1"
            }
          })
        }        
      }
      if (result)
        container.dataset.filterset = result
      else
        delete container.dataset.filterset
    }
    if (!unitsTree.length) return <div></div>
  
    return <ShowElementContext.Provider value={{
            openTeamsCallback, contextMenuCallback, 
            firstColumnStyle, icons}}>
      <div ref={contRef}>
        <FilterDocs callback={fillFilterCSS}/>
        <div styleName='unit unit-header'>
          <div styleName='unit-child' 
            style={firstColumnStyle}>
            {transS('Documents')}
          </div>  
          <div styleName='unit-child'>
            {transS('Counterparty & Teams')}
          </div>
          <div styleName='unit-child'>
            {transS('Status')}
          </div>
          <div styleName='unit-child unit-narrow'>
            {transS('Latest Activity')}
          </div>
          <div styleName='unit-child unit-wide'>
            {transS('Parameters')}
          </div>
        </div>
      {!!topElement && <div styleName='unit unit-header'>
          {!!topElement.ancestor && 
          <span styleName='unit-link' onClick={() => goHome(topElement.ancestor)}>
            {transS('..Level Up')}</span>}
         <span styleName='unit-link' onClick={() => goHome(null)}>
            {transS('All documents')}</span>
      </div>}

      {actual == loc_id ? list.map(el => <TreeNode 
        key={el.loc_id}
        unit={el}
        ShowElement={ShowElement}
        getChildren={getChildren}
        mark={el.mark}
        unfold={el.pinnedFolder}
      />)
      : <div styleName='spinner-place'>
        <Spinner />
      </div>
      }
          
    </div></ShowElementContext.Provider>
  }

export default CSSModules(Units, styles, {allowMultiple: true});
