import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transS } from '../../../../services/helpers/lang';
import { ButtonStd } from '../../../../components/button/Buttons';
import { isEmail } from "../../../../../common/validation";
import { Icon_x } from '../../../../components/svglib/svglib';
import { colors } from '../../../../components/colors';
import { TeamMember } from './TeamMember';
import api from '../../../../services/api/api';
import { unitConstants } from '../../../../services/reducers/unitReducer';

export const AddTeamMembers = ({external, fashion, memberList, cb}) => {
  const refPopup = useRef(),
    dispatch = useDispatch(),
    {knownUsers, companyTree} = useSelector(state => state.unitReducer)

  const [candidates, setCandidates] = useState([]),
    [isShowAddBtn, setIsShowAddBtn] = useState(false),
    [inputValue, setInputValue] = useState(''),
    [addedList, setAddedList] = useState([]),
    fixerSize = 3,
    [fixer, setFixer] = useState(fixerSize),
    [scrollPos, setScrollPos] = useState(0),
    [blocked, setBlocked] = useState(false),
    [mails, setMails] = useState([]),

    inputStyle = {
      display: 'flex',
      alignItems: 'center'
    },
    inputBtnStyle = {
      flexShrink: 0,
      flexGrow: 0,
      color: isShowAddBtn ? colors.primary : colors.white,
      cursor: isShowAddBtn ? "pointer" : "inherit",
      padding: '0 10px'
    },
    inputCrossStyle = {
      flexShrink: 0,
      flexGrow: 0,
      opacity: inputValue ? 1 : 0,
      cursor: inputValue ? "pointer" : "inherit",
    },
    showMoreStyle = {
      alignSelf: 'start',
      backgroundColor: colors.white,
      color: colors.primary,
      border: `none`
    },
    justAddedStyle = {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      gap: 20,
      backgroundColor: fashion ? colors.externalTeam : colors.internalTeam,
      borderRadius: 8,
      margin: '0 -20px',
    },
    buttonsStyle = {
      display: 'flex',
      alignSelf: 'start',
      gap: 20
    }

  
  useEffect( () => {
    const candidates = [], mails = []
    knownUsers.forEach((user, id) => {
      if (!memberList.find(member => member.id === id)
        && !addedList.find(member => member.userid === id)
      )
        candidates.push(user)
      else mails.push(user.email?.toLowerCase())
    })
    if (!external && companyTree)
      companyTree.forEach(el => {
        if (!memberList.find(member => member.id === el.loc_id)
        && !addedList.find(member => member.loc_id === el.loc_id)
      )
        candidates.push(el)
      })
    setCandidates(candidates) 
    memberList.forEach(m => m.justAdded && mails.push(m.justAdded.email))
    setMails(mails)
    }, [knownUsers, memberList, addedList, companyTree])

  useEffect(() => {
    const clickOutsideHandler = (e) => {
      if (refPopup.current && (refPopup.current === e.target ||
        !refPopup.current.contains(e.target))) {
          e.stopPropagation()
          e.preventDefault()
          cb(false)      
        }
    }
    window.addEventListener('click', clickOutsideHandler)
    return () => {
        window.removeEventListener('click', clickOutsideHandler)
    }
  }, [])

  useEffect(() => {
    const searchString = inputValue.trim().toLowerCase(),
      isEmailReal = !isEmail(searchString),
      found = isEmailReal && 
        (candidatesFiltered.find(el => el.email?.toLowerCase() === searchString)
        || !candidates.find(el => el.email?.toLowerCase() === searchString) 
        && !mails.find(mail => mail === searchString)
        && !addedList.find(el => el.email?.toLowerCase() === searchString)
        ),
      showCondition = (candidatesFiltered.length === 1 && inputValue) || found
    if (showCondition && !isShowAddBtn) {
        setIsShowAddBtn(true);
    } else if (!showCondition && isShowAddBtn) {
        setIsShowAddBtn(false);
    }
  }, [inputValue, candidates]);

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const clearInput = () => {
      setInputValue('');
      setIsShowAddBtn(false);
  };

  const inputKeyDownHandler = (e) => {
      if (e.key === 'Enter') addMember();
  };

  const addMember = async () => {
    if (candidatesFiltered.length === 1) {
      setAddedList([...addedList, candidatesFiltered[0]])
    } else if (!isEmail(inputValue)) {
      const newEmail = inputValue.trim()
      if (!addedList.find(el => el.email.toLowerCase() === newEmail.toLowerCase())) {
        setBlocked(true)
        setInputValue(transS('...searching'))
        try {
          const response = await api.checkEmail({email: newEmail})
          if (response?.status == 200) {
            const {user} = response.data.data
            if (user.userid)
              dispatch( {type: unitConstants.KNOWN_USER, user})
            else 
              user.userid = -Date.now()
            setAddedList([...addedList, user])
          }    
        } catch {}  
        setBlocked(false)
      }      
    }
    setInputValue('');
    setIsShowAddBtn(false);
  };

  const memberCb = (_, command, id) => {
    if (command === 'addMember') {
      const found = candidates.find(c => c.userid === id || c.loc_id === id)
      setAddedList([...addedList, found])
    } else {
      const idx = addedList.findIndex(c => c.userid === id || c.loc_id === id)
      addedList.splice(idx,1)
      setAddedList([...addedList])
    }
  }

  const showMoreToggle = () => {
    if (fixer === fixerSize) {
      setScrollPos(window.scrollY)
      setFixer(1000)
    } else {
      window.scrollTo(window.scrollX, scrollPos)
      setFixer(fixerSize)
    }
  }

  const filterCandidates = (member, search) => {
    const searchString = search.trim().toLowerCase()
    if (!searchString) return true
    return `${member.firstname}$${member.lastname}$${member.email}${member.team_name}`
      .toLowerCase().includes(searchString)     
  }

  const candidatesFiltered = candidates.filter(
    memberItem => filterCandidates(memberItem, inputValue))

  return <div className='dev-member' ref={refPopup}>
    <div>
      <div className='h3'>{transS('Add Members')}</div>
      <div style={inputStyle}>
        <input className='dev-std-input'
          placeholder={candidatesFiltered.length
            ? transS('Search emails, names, or groups')
            : transS('Type Email')} 
          value={inputValue}
          onChange={inputChangeHandler}
          onKeyDown={inputKeyDownHandler}
        />
        <span style={inputBtnStyle}
          onClick={() => isShowAddBtn ? addMember() : null}>
          {transS('Add')}
        </span>
        <span style={inputCrossStyle}
          onClick={clearInput}
        > <Icon_x /></span>
      </div>

      {addedList.length ? 
      <div style={justAddedStyle}>
        <span className="h4">{transS('Just Added')}</span>
        {addedList.map(memberItem => <TeamMember 
          key={memberItem.userid || memberItem.loc_id}
          luserId={memberItem.userid || memberItem.loc_id}
          callback={memberCb}
          removable="1"
          userObj={memberItem}
          extension={null}
        /> )}
      </div> : false }

      {!!candidatesFiltered.length && <div className="h4" >
        {transS('Select a person')}
      </div>}

      {candidatesFiltered.map((memberItem, i) => 
        i < fixer ? <TeamMember 
        key={memberItem.userid || memberItem.loc_id}
        luserId={memberItem.userid || memberItem.loc_id}
        callback={memberCb}
        extension={null}
        userObj={memberItem}
        clickable="1"
      /> : false)}

      {candidatesFiltered.length <= fixerSize ? false
      : <ButtonStd 
        text={transS(fixer === fixerSize ? 'Show More' :'Show Less')}
        style={showMoreStyle}
        action={showMoreToggle}
      />}
      <div style={buttonsStyle}>
        <ButtonStd text={transS('Done')} 
          action={() => cb(addedList)}
        />
        <ButtonStd text={transS('Cancel')} 
          style={{
            backgroundColor: colors.white,
            color: colors.primary,
          }}
          action={() => cb(false)}
        />
      </div>
    </div>
  </div>
}