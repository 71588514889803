import moment from 'moment';
import { origVariants, rights } from '../../../../../configs/appConfig';
import { transS, transF } from '../../../../services/helpers/lang';
import api from '../../../../services/api/api';
import store from '../../../../services/store';

export const makeNewUnit = (unit, luserId) => {
  const iAmInternal = unit?.integrated & rights.INTERNAL,
	iAmCreator = unit?.integrated & rights.CREATOR,
	right = !unit ? rights.INTERNAL + rights.CREATOR + rights.ADMIN_UNIT + rights.REP
	  : iAmInternal ? (iAmCreator ? 0 : rights.CREATOR)
	  : (iAmCreator ? 0 : rights.CREATOR)
  return ({ancestor: unit?.loc_id || null,
	parameters: [],
	members: right ? [luserId] : [],
	rights: right ? [right] : [],
	adds: [], changes: [], removes: [],
  })  
}

export const justifyNewUnit = unit => {
  const id = unit.members[0], right = unit.rights[0],
    addedCreator = {userid: id, email: '', right}
  if (!id)
	return
  if (unit.changes.length) {
	addedCreator.right = unit.changes[0].right
	unit.adds.unshift(addedCreator)
	unit.changes = []
	return
  } 
  const found = unit.adds.find(a => a.userid === id)
  if (found)
  	found.right |= right
  else
  	unit.adds.unshift(addedCreator)
}

export const unitHelper = (unit, luserId) => {    
	const isDoc = !!(unit.d_created || unit.orig),
		contentType = unit.contentType,
		isFolder = !isDoc && !contentType,
		hasChildren = !!unit.folders?.length, 
		iAmInternal = unit.integrated & rights.INTERNAL,
		canEditTeams = unit.integrated & (rights.ADMIN_COMPANY + 
			rights.ADMIN_UNIT + rights.CREATOR + rights.MANAGE_UNIT) ||
			!iAmInternal && unit.integrated & rights.TEAM_UNIT,
		haveGroupRights = unit.rights && 
		  unit.rights.reduce((acc, r) => acc | r, 0) & rights.ALL_RIGHTS
		  || 0,
		isInternal = iAmInternal && haveGroupRights & rights.INTERNAL,
		hasExternals = haveGroupRights && (!iAmInternal || 
			haveGroupRights & rights.EXTERNAL),
		inGroup = iAmInternal && !unit.editorsgroup ||
			unit.editorsgroup && !iAmInternal,  // only for docs
		status = isDoc && unit.orig === origVariants.collaboration ? 'orig-collaboration' :
			unit.orig === origVariants.fin_request ? (inGroup ? 'orig-finByThem' : 'orig-finByUs'):
			unit.orig === origVariants.signed ? 'orig-signed' :
			unit.orig === origVariants.signing ? 'orig-signing' :
			unit.orig === origVariants.signed_ext ? 'orig-signThem' :
			unit.orig === origVariants.signed_int ? 'orig-signUs' :
			unit.orig === origVariants.final ? 'orig-final' :
			unit.orig === origVariants.draft ? 'orig-draft' :
			inGroup ? 'orig-internal' : 'orig-external',
		approvalsCount = iAmInternal && inGroup && unit.approvalsSet?.size || 0,
		approvedCount = approvalsCount && unit.approved?.length || 0,
		checkApprove = status === 'orig-internal' 
				|| unit.orig === 6 && iAmInternal && !unit.editorsgroup,

		docProps = isDoc && {
			status,
			amIApprover: iAmInternal && unit.integrated & rights.APPROVE,
			editor: unit.editor,
			edited: +unit.edited,
			lastAuthor: (+unit.edited > +unit.d_created && unit.editing_by)
				? unit.editing_by : unit.editor,
			lastActivity: (+unit.edited > +unit.d_created && unit.editing_by) 
				? +unit.edited : +unit.d_created,
			checkChanges: status === 'orig-internal' || status === 'orig-collaboration',
			externalOpenInfo: status === 'orig-external' && 
				transF('SentOpen')(`${moment(+unit.d_created).fromNow().replace(' ago','')
				}.${+unit.wasopened ? (' Opened ' + moment(+unit.wasopened).fromNow())
				: ' Not opened yet'}`).replace('..', '.'),
			checkApprove,        
			approvalsCount, approvedCount,        
			approvalRequired: checkApprove && approvalsCount > approvedCount &&
				iAmInternal && unit.integrated & rights.APPROVE &&
				(!approvedCount || !unit.approved.find(x => x === luserId))
		}
    //   docHere = unit.orig && [1, 3, 4, 7, 10, 13, 20, 21, 50, 302].includes(unit.orig),
    //   docThere = unit.orig && [2, 8, 9, 14].includes(unit.orig),
    //   docBoth = unit.orig && [6, 12, 22, 23, 24].includes(unit.orig)
      
    return {
      isDoc, contentType, isFolder, hasChildren, iAmInternal, canEditTeams,
      isInternal, hasExternals, inGroup, docProps
    }
  }

export const getFolderNewName = (parentId) => {
  const red = store.getState().unitReducer,
	listNames = red.unitsTree.filter(t => t.ancestor == parentId)
	  .map(item => item.title),
	prefix = transS('New Folder ')

  let name = prefix, counter = 1
  while (listNames.includes(name))
	name = `${prefix}${counter++}`
  return name
}


export const resetTeamMembers = (unit, userId, integrated, nextParent) => {
	// calcs inherited rights and make new teams()
	let parent = nextParent(unit)
	while (parent && !parent.members?.length)
	  parent = nextParent(parent)
	
	unit.members = parent ? [...parent.members] : [userId]
  	unit.rights = parent ? [...parent.rights] : [integrated]
		
	if (parent) {
		const idx = unit.members.findIndex(el => el === userId)
		unit.rights[idx] = integrated
	}
}

const addRightToTeam = (team, user, right) => {
  const i = team.findIndex(u => u.id === user)
  if (i === -1)
	team.push({id: user, right, directRight: 0})
  else
	team[i].right |= right
}

export const findUserTeams = (user, companyTree) => {
  const result =[user]
  if (!companyTree)
	return result
  companyTree.forEach(team => {
	if (team.members.includes(user))
		result.push(team.loc_id)
  })
  return result
}

export const findUnitTeams = (unit, getUnit, userIds, clear) => {
  const teams = []
  let parent = unit.ancestor ? getUnit(unit.ancestor) : null,
    onTopRights = true

  if (!clear)
  unit.members?.forEach((el,i) => {
	const right = unit.rights[i] & rights.FILTER_RIGHTS
	teams.push({id: el, right, directRight: right})
  })

  while (parent) {
	parent.members?.forEach((el,i) => {
	  const right = parent.rights[i] & rights.FILTER_RIGHTS
	  addRightToTeam(teams, el, right )
	  onTopRights = false
	})
	parent = parent.ancestor ? getUnit(parent.ancestor) : null
  }  

  const internalTeam = teams.filter(u => u.right & rights.INTERNAL), 
  	externalTeam = teams.filter(u => u.right & rights.EXTERNAL), 
	userRights = teams.reduce((acc, u) => 
	  acc | (userIds.includes(u.id) ? u.right : 0), 0),
	inInternal = userRights & rights.INTERNAL,
    externalNumber = userRights & rights.EXTERNAL,
    internalEditor = inInternal && (userRights & rights.CAN_REMOVE),
    externalEditor = externalNumber && (userRights & rights.TEAM_UNIT),
	result = {adds: [], removes: [], changes: []}

  return ({teams, internalTeam, externalTeam, userRights, onTopRights,
	inInternal, externalNumber, internalEditor, externalEditor, result})
}

export const getAllTeamMembers = (companyTree, id, found = new Set()) => {
  const node = companyTree.find(n => n.loc_id === id)
  node.members?.forEach(m => m && found.add(m))
  node.folders?.forEach(f => f && getAllTeamMembers(companyTree, f, found))
  return found
}

export const prepareFileList = async (fileList) => {
  if (!fileList?.length)
  	return null

  const processFile = (f) => {
    return new Promise((resolve, reject) => {
	  const reader = new FileReader()

	  reader.onloadend = () => {
		resolve({
	  	name: f.name,
	  	contentType: f.file.type,
	  	content: reader.result, 
	  })
	  }

	  reader.onerror = reject
	  reader.readAsDataURL(f.file)
	})
  }

  try {
    const files = await Promise.all(fileList.map(processFile));
    return  files
  } catch (error) {
    console.error("file processing error:", error);
    return null
  }
}
