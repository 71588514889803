export const colors = {}
colors.primary= "#157EFB"
colors.primary_hover= "#1DA6E5"
colors.primary_hover__dark= "#0368e0"
colors.primary_semi= "#b7d6fc"
colors.black= "#212121"
colors.darkGray= "#606060"
colors.gray= "#ACACAC"
colors.arrowGray= "#979797", 
colors.gray_hover= "rgba(172, 172, 172, 0.75)"
colors.lightGray2= "#DEDEDE"
colors.lightGray3= "#F7F7F7"
colors.lightGray= "#EEEEEE"
colors.white= "#FFF"
colors.green= "#00AB26"
colors.green_hover= "rgba(0, 171, 38, 0.75)"
colors.darkRed= "#D0021B"
colors.darkRed_hover= "rgba(208, 2, 27, 0.75)"
colors.lightRed= "#FF6C6C"
colors.orange= "#F89540"
colors.orange_hover= "#FFB85C"
colors.orange_semi= "#F8954080"
colors.yellow_light= "rgba(255, 233, 0, 0.2)"
colors.yellow_semi= "rgba(255, 233, 0, 0.5)"
colors.yellow_frame= "#ffeb3b38"
colors.approve = "#AF00CC"
colors.shadowMenu = "0 1px 20px 0 rgba(0, 0, 0, 0.11)"
colors.externalTeam = "#f3f8ff"
colors.externalJust = "#e7f1ff"
colors.internalTeam = "#F9F9F9"
colors.internalJust = "#EEEEEE"
colors.externalIcon = "#8abefd"

export const fontSize = {
    h1: "35px",
    h2: "21px",
    h3: "17px",
    h4: "15px",
    tab: "15px",
    standart: "13px",
    tip: "11px",
    button: "13px",
    bigPopup: "24px",
    readable: "15px",
}

export const minWithSize = 1286
