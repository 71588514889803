import { setDisplayOnlyAction, setDocumentNavigationAction } from "../../../../redux/document/actions";
import { blockParagraphsAction } from "../../../../redux/paragraph/actions";
import { getPopUpAction } from "../../../../services/actions/dataActions";
import { resetPragraphListAction, setFrameAction, setJumpChanges, setNoticeBannerContent } from "../../../../services/actions/paragraphActions";
import api from "../../../../services/api/api";
import { popUpReset } from "../../../../services/helpers";
import { saveStats, eventTypes, eventSubtypes } from "../../../../services/helpers/logger";
import { unitConstants } from "../../../../services/reducers/unitReducer";
import { handleFrameTasks, setFrameProps } from "./handleFrameTasks";

let thisFrame = {
  openTimestamp: 0,
  docUnitId: null,
  isDocument: null,
  dispatch: null,
  location: null, 
  history: null,
  user: null,
  sendToFrame,
  setOverlineParams: null,
  setDateVar: null, 
  setVarList: null,
  popupMode: null,
}, lastEventTimestamp = Date.now(), readingCheckInterval

export const sendToFrame = (data) => 
  frames['iframe']?.window?.postMessage(
    JSON.stringify(data), document.location.origin)

const userEventHandler = () => {
  if (Date.now() - lastEventTimestamp < 2000) return
  lastEventTimestamp = Date.now()
},
pageUnloadHandler = () => {
  const {openTimestamp, docUnitId, isDocument} = thisFrame
  if (!openTimestamp) return
  saveStats({type: eventTypes.DOCUMENT, subtype: 
    eventSubtypes.READING_DOCUMENT, 
    project: null,
    document: docUnitId, 
    when: Date.now(),
    attributes: {duration: Date.now() - thisFrame.openTimestamp, 
      version: isDocument.version, 
      group: isDocument.userGroup
    }})
}

export const setFrameParams = (params) => {
  thisFrame = {...thisFrame, ...params}
  thisFrame.sendToFrame = sendToFrame
  setFrameProps(thisFrame)
}

const registerListeners = () => {
    window.addEventListener('mousemove', userEventHandler);
    window.addEventListener('scroll', userEventHandler);
    window.addEventListener('unload', pageUnloadHandler);
    window.addEventListener('keydown', pageKeyHandler);
}, unregisterListeners = () => {
      window.removeEventListener('mousemove', userEventHandler);
      window.removeEventListener('scroll', userEventHandler);
      window.removeEventListener('unload', pageUnloadHandler);
      window.removeEventListener('keydown', pageKeyHandler);

      window.removeEventListener("message", handleFrameTasks);
      window.removeEventListener('scroll', _frameScroll); //!
      window.removeEventListener("resize", handleResize)  //!
}

export const afterFrameLoad = () => {
  const {sendToFrame, dispatch} = thisFrame
  thisFrame.openTimestamp = 0
  window.addEventListener("message", handleFrameTasks)
  window.addEventListener("resize", handleResize)
  window.addEventListener('scroll', _frameScroll)
  dispatch(setFrameAction(sendToFrame,
    {
      sendToFrame,
      dragHistory: fakeReplace.dragHistory, 
      dragTasks: fakeReplace.dragTasks,
      jumpDoc: fakeReplace.jumpDoc,
      prohibitNewVersion: fakeReplace.prohibitNewVersion,
      collapseComments: state => 
        sendToFrame({c1: 'collapse', toCollapse: state}),
      jumpDocChanges: (direction) => 
        sendToFrame({c1:"jumpChanges", direction}),   
      createVariable: (type) => 
        sendToFrame({c1: 'variable', action: 'create', type})
    }) 
  )
}

export const initActions = (initFrameVars) => {
  const {dispatch, docUnitId} = initFrameVars
  setFrameParams(initFrameVars)
  registerListeners()
  checkLocationForDocusign()

  window.scrollTo(0, 0)
  setReadingCheckInterval()
  dispatch(setDisplayOnlyAction(false, ''))
  dispatch({ type: unitConstants.DOCUMENT, data: docUnitId})    
  dispatch(blockParagraphsAction(true))
  dispatch(setDocumentNavigationAction(null, null))

    return () => {
      // dismount
      unregisterListeners()
      dispatch({ type: unitConstants.DOCUMENT, data: null})
      if (readingCheckInterval) clearInterval(readingCheckInterval)
      dispatch(resetPragraphListAction())
      dispatch(setDisplayOnlyAction(false, ''))
      dispatch(setJumpChanges({vis: false}))
      dispatch(setNoticeBannerContent(null))
      pageUnloadHandler()
    }
}

  
const checkLocationForDocusign = () => {
  const {location, history, user, docUnitId, dispatch} = thisFrame
  if (location.search) {
    const queryParams = new URLSearchParams(location.search)
    const envelopeId = queryParams.get("envelopeId")
    const envelopeId1 = queryParams.get("env")
    const event = queryParams.get("event")
    if (event === 'popup') {
      let diffMail = queryParams.get("email");
      dispatch(getPopUpAction(
        {
          name: 'confirm',
          header: diffMail ? 'Wrong DocuSign account' : 'Error with DocuSign account',
          text: diffMail ?
              `You logged in with ${diffMail}.  <br><br> Please sign in to DocuSign with ${user.email} or go to ${user.email} and sign via the link from email.`
            : `Please sign with the link that was sent to your email ${user.email}.`,
          cancel: {
              name: transS('Got it'),
              mod: 'blue arch big',
              event: () => popupCall(popUpReset)
          }
      }))            
    }
    else if (event && event !== 'Save' && (envelopeId || event !== 'Cancel'))
       api.signDocumentCheck(envelopeId || envelopeId1, event, docUnitId, null);
    history.push(location.pathname)
  }
}

export const setReadingCheckInterval = () => null
/*
this.readingCheckInterval = setInterval(() => {
        const isUserSleep = Date.now() - this.state.lastEventTimestamp > 120000 // 2 минуты без движения мышки или скролла - юзер ушёл в "сон"
        if ( (document.hidden || isUserSleep || this.state.popupMode) && this.openTimestamp) { // свернули браузер/переключили вкладку или юзер "уснул"

            this.props.saveStats({type: eventTypes.DOCUMENT, subtype: eventSubtypes.READING_DOCUMENT, project: this.props.currentProject._id,
                document: this.props.actualDocument.coreDocument, when: Date.now(),
                attributes: {duration: Date.now() - this.openTimestamp, version: this.props.actualDocument.version, group: this.props.user.userGroup}})

            return this.openTimestamp = 0
        }
        if (!this.openTimestamp && !document.hidden && !isUserSleep && !this.state.popupMode) {
            this.openTimestamp = Date.now()
        } // снова открыли браузер/вкладку или юзер "проснулся"
    }, 15000)
*/

let callbacks
export const helperInfo = info => {
  callbacks = info
}

export const helperFrameEvents = msg => {
  switch (msg.c1) {
    case 'frame': 
      _frameScroll()
      callbacks.setDocReady(true)
      break
  }
}


const _frameScroll = () => 
  thisFrame.sendToFrame({c1:'position',
    documentHeight: Math.max(document.body.scrollHeight, 
      document.body.offsetHeight, 
      document.documentElement.clientHeight, 
      document.documentElement.scrollHeight, 
      document.documentElement.offsetHeight
      ),
      windowHeight: document.documentElement.clientHeight 
        || window.innerHeight 
        || document.getElementsByTagName('body')[0].clientHeight,
      scrollY: window.scrollY
    } )

const handleResize = () => {
  const frame = frames['iframe']?.document?.documentElement
  if (!frame)
      return
  const frameRem = 
      parseFloat(getComputedStyle(frame).fontSize),
      myRem = parseFloat(getComputedStyle(document.documentElement).fontSize)
  if (frameRem === myRem)
      return
  frame.style.fontSize = `${myRem}px`
}

const pageKeyHandler = (e) => {
  userEventHandler()
  if (e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'INPUT'
      && (e.ctrlKey || e.metaKey) && ((e.code === "KeyZ") || (e.code === "KeyY")) ) {
        this.sendToFrame({c1: e.code === "KeyZ" ? 'undo' : 'redo'});
      e.preventDefault();
      e.stopPropagation();
    }
}

const fakeReplace = {
  dragHistory: true, // () => this.history, 
  dragTasks: true, //() => this.tasks,
  jumpDoc: () => null, //this._jumpDoc,
  prohibitNewVersion: false, //(oldPermissionCheck() && user.ticks <= 0) || displayOnly || actualDocument.blocked,
}
