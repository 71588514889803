import React, { useState } from 'react';
import { colors } from '../../../../components/colors';
import { ButtonStd } from '../../../../components/button/Buttons';
import { transS } from '../../../../services/helpers/lang';
import { TeamMember } from './TeamMember';
import { AddTeamMembers } from './AddTeamMembers';
import { AddCompanyTeams } from './AddCompanyTeams';

export const Team = ({external, fashion, title, titleEditable, canAdd, canApprove,
  memberList, alter, action, company}) => {
    
  const [dialog, setDialog] = useState(false),
  [editTitle, setEditTitle] = useState(false),
  [newTitle, setNewTitle] = useState(title),
  styleCont = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,
    padding: 40,
    width: 400,
    borderRadius: 8,
    backgroundColor: fashion ? colors.externalTeam : colors.internalTeam
  }, whiteButton = {
    backgroundColor: colors.white,
    color: colors.primary,
  }, containerButtons = {
    position: 'relative',
    display: 'flex',
    gap: 20
  },
  justAddedStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    gap: 20,
    backgroundColor: fashion ? colors.externalJust : colors.internalJust,
    borderRadius: 8,
    margin: '0 -20px',
  },
  cb = result => {
    if (result)
      action(external, 'add', result)
    setDialog(false)
  }

  const justAddedMembers = memberList.filter(el => el.justAdded)

  const inputChangeHandler = (e) => {
    setNewTitle(e.target.value)
    action(external, 'title', e.target.value)
  };


  const inputKeyDownHandler = (e) => {
      if (e.key === 'Enter') {
        e.target.blur()
      }
  };

  return <div style={styleCont}>
    {editTitle ? false :
      <div className={'h3' + (titleEditable  ? ' pointable' : '')}
      onClick={() => titleEditable ? setEditTitle(true) : null}>
      {title}
    </div>}
    {editTitle && <input className='dev-std-input h3'          
          value={newTitle}
          onChange={inputChangeHandler}
          onKeyDown={inputKeyDownHandler} />}
    {!!canAdd && <div></div>}
    {!!canAdd && <div style={containerButtons}>
      <ButtonStd style={whiteButton}
        text={transS('Add Members')}
        action={() => setDialog('members')}
      />
      {!!company && !!canApprove && <ButtonStd style={whiteButton}
        text={transS('Add Teams')}
        action={() => setDialog('teams')}
      />}
      {dialog && (dialog === 'members'
        ? <AddTeamMembers external={external} fashion={fashion}
            memberList={[...memberList, ...alter]} cb={cb} />
        : <AddCompanyTeams memberList={memberList} cb={cb} />
      )}
    </div>}
    {justAddedMembers.length ? 
      <div style={justAddedStyle}>
        <span className="h4">{transS('Just Added')}</span>
        {justAddedMembers.map(memberItem => <TeamMember 
          key={memberItem.id}
          luserId={memberItem.id}
          external={external}
          callback={action}
          right={memberItem.right} 
          directRight={memberItem.directRight}
          editable={canApprove}
          removable={canAdd}
          userObj={memberItem.justAdded}
          extension={null}
        /> )}
      </div> : false }
    {memberList.filter(el => !el.justAdded)
      .map(memberItem => <TeamMember 
      key={memberItem.id}
      luserId={memberItem.id}
      external={external}
      callback={action}
      right={memberItem.right} 
      directRight={memberItem.directRight}
      editable={canApprove}
      removable={canAdd}
      extension={null}
    /> )}    
  </div>
}