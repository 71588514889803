import React from 'react'
import {connect} from 'react-redux';
import {NavLink, Redirect} from 'react-router-dom';

import styles from './topMenu.css';
import cx from 'classnames';
import CSSModules from "react-css-modules";
import moment from 'moment';

import api from '../../../../services/api/api';
import {downloader} from '../../../../services/helpers/download';

import Logo from "../../../../components/logo/Logo";
import NoticeBanner from "../../../../components/eventBanner/NoticeBanner";
import Button from "../../../../components/button/Button";
import UserLogo from "../../../../components/userLogo/UserLogo";
import SideBar from "./elements/SideBar/sideBar";
import Account from '../accountComponent/accountComponent';
import Payment from '../paymentComponent/PaymentComponent';
import UserListItem from '../createProjectDropDown/UserListItem';
import ProjectPage from "../projectPage/projectPage";
import { getDateString } from "../../../../../common/getDateString"

import {
    copyDocumentAction,
    remindUserDocumentApproveAction,
    renameDocumentAction, signDocumentAction
} from '../../../../services/actions/documentActions'
import {getPopUpAction, toggleLoaderAction} from '../../../../services/actions/dataActions';
import {
    approveDocumentEditingAction,
    disapproveDocumentEditingAction,
    getHistoryDocumentAction,
    overtakeEditingAction,
    renameDocumentFromSidebarAction,
    requestDocumentEditingAction,
    saveNewDocumentSubVersionAction, toggleNavigationVisAction,
    returnSubVersionAction,
    transferDocumentEditingAction,
} from "../../../../redux/document/actions";
import {getInvitePopupAction} from "../../../../services/actions/projectActions";
import {setMenuAction, setPopupMode} from "../../../../services/actions/paragraphActions";
import {hasDownloadDocumentPermission} from '../../../../../common/validation';
import {DisplayVersion} from "./TopMenuModes/DisplayVersion"
import {ReviewedInternal} from "./TopMenuModes/ReviewedInternal"
import {ReviewedExternal} from "./TopMenuModes/ReviewedExternal"
import {Finalized} from "./TopMenuModes/Finalized"
import {FinalizedInternal} from "./TopMenuModes/FinalizedInternal"
import {FinalizedExternal} from "./TopMenuModes/FinalizedExternal"
import {FinalizeExternalRefuse} from "./TopMenuModes/FinalizeExternalRefuse"
import {FinalizeCancelInternal} from "./TopMenuModes/FinalizeCancelInternal"
import {FinalizeCancelExternal} from "./TopMenuModes/FinalizeCancelExternal"
import {TempUserBlock} from "./elements/TempUserBlock/TempUserBlock"
import {isEqual} from 'lodash'
import {BrowserSupportNotice} from '../../../../components/browserSupport/BrowserSupportNotice'
import Templates from './TopMenuModes/TemplatesView'
import TemplatesUnderMenu from './elements/TemplatesUnderMenu/TemplatesUnderMenu'
import DocControlsUnderMenu from './elements/TemplatesUnderMenu/DocControlsUnderMenu'
import { transF, transL, transS } from '../../../../services/helpers/lang';
import { TopMain, TopMainLine } from './TopMain'
import { TopDocMenu } from './TopDocMenu';
import { getStoreHeap } from '../../../../services/store/storeHelper';
import { LeftMenu } from './elements/SideBar/LeftMenu';

@connect(state => ({
    user: state.userReducer.user,
    firstTime: state.userReducer.firstTime,
    dateData: state.userReducer.dateData,
    approverUserList: state.userReducer.approverUserList,
    hasExternals: state.userReducer.hasExternals,
    externalUserList: state.userReducer.externalUserList,
    internalUserList: state.userReducer.internalUserList,
    paragraphList: state.paragraphReducer.paragraphList,
    frameProgress: state.paragraphReducer.frameProgress,
    frameCallback: state.paragraphReducer.frameCallback,
    stateMenu: state.paragraphReducer.stateMenu,
    tasksCount: state.paragraphReducer.tasksCount,
    projectCreator: state.projectReducer.currentProject ? state.projectReducer.currentProject.creator : null,
    currentProject: state.projectReducer.currentProject,
    actualDocument: state.document.actualDocument,
    approved: state.document.approved,
    envelope: state.document.envelope,
    currentDocument: state.document.currentDocument,
    showSidebar: state.userReducer.showSidebar,
    showPayment: state.userReducer.showPayment,
    showExpired: state.userReducer.showExpired,
    displayVersion: state.document.displayVersion,
    invitePopup: state.projectReducer.invitePopup,
    projectList: state.projectReducer.projectList,
    jumpChanges: state.paragraphReducer.jumpChanges,
    isDocument: state.unitReducer.isDocument,
}), {
    saveNewDocumentSubVersionAction,
    returnSubVersionAction,
    transferDocumentEditingAction,
    approveDocumentEditingAction,
    disapproveDocumentEditingAction,
    requestDocumentEditingAction,
    overtakeEditingAction,
    getPopUpAction,
    renameDocumentAction,
    renameDocumentFromSidebarAction,
    getHistoryDocumentAction,
    setMenuAction,
    getInvitePopupAction,
    remindUserDocumentApproveAction,
    copyDocumentAction,
    setPopupMode,
    signDocumentAction,
    toggleLoaderAction,
    toggleNavigationVisAction
})

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

class TopMenu extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.renameDocumentHandler = this.renameDocumentHandler.bind(this)
        this.freezeFunc = this.freezeFunc.bind(this);
        this.state = {
            documentNameWidth: null,
            projectNameWidth: null,
            isTitleCut: false,
            isHint: false,
            isSideBarOpen: false,
            toCollaborate: false,
            intVersionBarIsOpen: false,

            isTeamsOpen: false, // projectPage from SideBar
            copyDocumentBlocked: false,
            documentCoverMessages: [],
        }
    }

    componentDidMount() {
        if (this.props.actualDocument.title && this.props.currentDocument) {
            this.calculateWidthDocumentName()
        }
        this.redirects()

        document.addEventListener('mouseup', this.handleClickOutside)
    }

    componentDidUpdate(prevProps) {
        if (this.props.invitePopup && !this.props.invitePopup.show && this.state.isTeamsOpen) {
            this.setState({isTeamsOpen: false})
        }

        if (this.props.actualDocument.title &&
            (prevProps.actualDocument.title !== this.props.actualDocument.title || prevProps.actualDocument._id !== this.props.actualDocument._id)) {
            this.calculateWidthDocumentName(true);
        } else if (!this.props.actualDocument.title && (this.state.projectNameWidth || this.state.projectNameWidth)) {
            this.setState({isTitleCut: false, isHint: false, projectNameWidth: null, documentNameWidth: null})
        }

        let inDoc = this.props.currentDocument && this.props.actualDocument && this.props.actualDocument._id
        let prevInDoc = prevProps.currentDocument && prevProps.actualDocument && prevProps.actualDocument._id
        if (inDoc !== prevInDoc) {
            this.setState({isSideBarOpen: false, intVersionBarIsOpen: false})
        }

       this.redirects()
    }

    redirects = () => {
        if (window.location.hash && this.props.projectList) {
            const queryParams = new URLSearchParams(window.location.hash.slice(1))
            const redirect = queryParams.get('redirect')
            if (!redirect) return
            if (redirect === 'newProject') this.props.getInvitePopupAction(true)
            window.history.pushState('', '', '/')
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    versionBarOpenHandler = (value) => {
        let {frameProgress} = this.props;
        if (!frameProgress.toggleBar) return; 
        frameProgress.toggleBar(value);
        this.setState({intVersionBarIsOpen: value});
    }

    renameDocumentHandler(id, newName) {
        this.props.renameDocumentAction(id, newName)
        this.props.renameDocumentFromSidebarAction(newName)
        this.setState({projectNameWidth: null, documentNameWidth: null})
    }

    handleClickOutside = (event) => {
        if (this.state.isSideBarOpen && this.refSidebar && event.target !== this.refSidebar && !this.refSidebar.contains(event.target)) {
            this.sideBarOpenHandler(false)
        }
    }
    
    freezeFunc(toImport, confirmImport) {
       const {returnSubVersionAction, getPopUpAction, saveNewDocumentSubVersionAction, 
           popUpReset, currentDocument} = this.props;
       this.sideBarOpenHandler(false);
       if (confirmImport) {
        if (toImport)
          getPopUpAction({
            header: 'Are you okay with the imported version?',
            name: 'confirm',
            text: `Due to the unique way MS Word handles data, imported changes might not always be accurate.`,
            notification: `Please review the imported document. If everything looks correct, continue to editing.`,
            confirm: {
              name: 'Continue',
              event: () => { saveNewDocumentSubVersionAction(currentDocument.coreDocument); }
            },
            cancel: {
              name: 'Check',
              event: () => { getPopUpAction(popUpReset); }
            }
          }) 
        else
          getPopUpAction({
            header: transS('Return to Previous Version'),
            name: 'confirm',
            text: 'Do you want to delete this version and return to the previous one?',
            swap: true,
            confirm: {
              name: 'Return',
              danger: true,
              event: () => { returnSubVersionAction(currentDocument._id); }
            },
            cancel: {
              name: 'Cancel',
              event: () => { getPopUpAction(popUpReset); }
            }
         });           
       }
       else if (toImport === null) {
        getPopUpAction({
            header: 'Lock document',
            name: 'send',
            text: `This document will be saved as a separate version and locked.`,
            notification: `It will remain locked until somebody unfreezes it or imports a new version.`,
            lockRecepients: true,
            confirm: {
              name: 'Lock',
              event: () => { saveNewDocumentSubVersionAction(currentDocument.coreDocument, true); }
            },
            cancel: {
              name: 'Cancel',
              event: () => { getPopUpAction(popUpReset); }
            }
         })
       }
       else if (toImport) {
          getPopUpAction({
            header: 'Upload New Version',
            name: 'confirm',
            text: `What you upload will become the current version of the document.`,
            notification: `We will do our best to make a precise comparison, but because the changes to this version were made outside of Contract.one, it is hard to guarantee full integrity of changes.`,
            ads: 'file',
            cancel: {
              name: '',
              event: () => { getPopUpAction(popUpReset); }
            }
          });
       } else {
          getPopUpAction({
            header: 'Unlock Version',
            name: 'confirm',
            swap: true,
            text: `This version is locked. Somebody is preparing the file’s import. If you unlock it now, there may be versions conflicts.`,
            question: `Do you still want to unlock this version?`,
            confirm: {
              name: 'Unlock',
              danger: true,
              event: () => { returnSubVersionAction(currentDocument._id); }
            },
            cancel: {
              name: 'Cancel',
              event: () => { getPopUpAction(popUpReset); }
            }
          });
       }
    }

    sideBarOpenHandler = (value) => {
        if (this.props.frameProgress?.toggleBar) this.props.frameProgress.toggleBar(false)
        this.setState({intVersionBarIsOpen: false});
        this.setState({isSideBarOpen: value})
    
        if (value) {
            this.clearSelection();
            document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px'
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.paddingRight = ''
            document.body.style.overflow = ''
        }
    }
    
    clearSelection = () => this.props.frameProgress?.sendToFrame &&
            this.props.frameProgress.sendToFrame({c1:'clearSelection'});

    tasksClickHandler = () => {
        this.sideBarOpenHandler(true);
        this.setState({intVersionBarIsOpen: true});
        this.props.frameProgress.toggleBar(true)
    }

    sibebarTeamsClickHandler = () => {
        const {currentProject, getInvitePopupAction} = this.props

        getInvitePopupAction(true, currentProject._id, true)
        this.setState({isTeamsOpen: true})
        this.sideBarOpenHandler(false)
    }

    calculateWidthDocumentName = (recalc) => {
        if (recalc || !this.state.projectNameWidth || !this.state.documentNameWidth) {

            let projectNameWidth = this.refProjectName ? this.refProjectName.offsetWidth : 0;
            let documentNameWidth = this.refDocumentName ? this.refDocumentName.offsetWidth :0;

            if (240 <= (projectNameWidth + documentNameWidth + 23)) {
                this.setState({isHint: true})
                this.setState({isTitleCut: true})
                let freeOffset = (240 - 24) / 2
                if (freeOffset > documentNameWidth) {
                    let dWidth = documentNameWidth
                    let pWidth = 240 - 24 - dWidth
                    this.setState({projectNameWidth: pWidth})
                    this.setState({documentNameWidth: dWidth})
                } else if (freeOffset > projectNameWidth) {
                    let pWidth = projectNameWidth + 1
                    let dWidth = 240 - 24 - pWidth
                    this.setState({projectNameWidth: pWidth})
                    this.setState({documentNameWidth: dWidth})
                } else {
                    this.setState({projectNameWidth: freeOffset})
                    this.setState({documentNameWidth: freeOffset})
                }

            } else if (this.state.isTitleCut) {
                this.setState({isTitleCut: false})
                this.setState({isHint: false})
                this.setState({projectNameWidth: null})
                this.setState({documentNameWidth: null})
            }
        } else {
            this.setState({isTitleCut: false})
            this.setState({isHint: false})
            this.setState({projectNameWidth: null})
            this.setState({documentNameWidth: null})
        }
    }

    _approvers = unApproved => {
        let {approverUserList, approved} = this.props;
        if (!approverUserList || !approverUserList.length) return false;
        return approverUserList.filter(el => unApproved === !approved.includes(el._id)).map(user =>
            <UserListItem
                user={user}
                key={user._id}
                remindProve={unApproved}
                remindUserDocumentApproveAction={this.props.remindUserDocumentApproveAction}
                noCheck={true}/>
        )
    };
    
    download = (format, version, subVersion, isHistory, callback) => {
       if (this.props.user.temporary) return this.props.getPopUpAction({name: 'auth'});
       const {actualDocument, displayVersion} = this.props, 
         downloadParams = {format, version, subVersion, isHistory, callback, actualDocument, displayVersion}
       this.setState({downloadParams});
       downloader(downloadParams);
    };

    copyDocument = () => {
        const {actualDocument, copyDocumentAction, currentProject} = this.props
        this.setState({copyDocumentBlocked: true})
        copyDocumentAction(actualDocument, currentProject.title).finally(() => {
            this.setState({copyDocumentBlocked: false})
        })
    }
    
    isDocChanged = async () => {
        const sleep = ms =>  {return new Promise(resolve => setTimeout(resolve, ms)); }    
        let {frameCallback} = this.props;
        if (!frameCallback) return true;
        const actualDocument = getStoreHeap()
        actualDocument.waiting_isDocChanged = null;
        frameCallback({c1:'isDocChanged'});
        for (let i=0; i<16; i++) {
           await sleep(30);
           if (actualDocument.waiting_isDocChanged === true)
               return true;
           if (actualDocument.waiting_isDocChanged === false)
               throw new Error('nChanged');
        }  
        return true;
    };

    approveBtnCallback = v => {
        const {disapproveDocumentEditingAction, approveDocumentEditingAction, actualDocument} = this.props;
        if (!v) this.clearSelection();
        return v ? disapproveDocumentEditingAction(actualDocument._id) : approveDocumentEditingAction(actualDocument._id)
    }

    render() {
        let {
            isDocument,
            approved, envelope, user, firstTime, currentProject, actualDocument, currentDocument, stateMenu, setMenuAction,
            displayVersion, approverUserList, invitePopup,
            saveNewDocumentSubVersionAction, getPopUpAction, popUpReset,
            transferDocumentEditingAction, requestDocumentEditingAction, overtakeEditingAction,
            hasExternals, frameProgress, internalUserList, jumpChanges, externalUserList, dateData,
            showSidebar, showPayment, showExpired, getHistoryDocumentAction, tasksCount, projectList, setPopupMode
        } = this.props;
        let {
            documentNameWidth, projectNameWidth, isSideBarOpen, isHint,
            isTitleCut, copyDocumentBlocked, documentCoverMessages
        } = this.state;
        let sideBarOpenHandler = this.sideBarOpenHandler, approveButton, canApprove, approvedByMe,
            inProject = currentProject && currentProject._id, isExternal = null,
            inDoc = inProject && currentDocument && actualDocument && actualDocument._id;
            //suiteCase = invitePopup && invitePopup.show && user && user._id && (user.plan && user.plan.includes('suite'));

        let isProjectAdmin = null, envelopeObj = {};
        if (!user || !user._id) return false;
        if (inProject && projectList && projectList.length) {
            let currentProjectCreator = null
            projectList.forEach(project => (project._id === currentProject._id) ? currentProjectCreator = project.creator : null)
            isProjectAdmin = currentProject.admins && !!currentProject.admins.find(i => i === user._id)
            if (internalUserList) {
                isExternal = !internalUserList.find(u => u._id === currentProjectCreator || (currentProject.admins && u._id === currentProject.admins[0]) )
                approverUserList = isExternal ? [] : approverUserList
            }
        }
        if (envelope) {
           if (envelope.status === 'completed') 
               envelopeObj.isSignedVersion = true;
           else if (envelope.status === 'declined')
               envelopeObj.isSignDeclined = true;
           else if (envelope.status === 'sent' || envelope.status === 'delivered') {
               envelopeObj.isProcessSigning = true;           
               let u = envelope.signee.find(c => c.userId === user._id || c.email.toLowerCase() === user.email.toLowerCase());
               if (u) { 
                  envelopeObj.isMySignNeed = true;
                  envelopeObj.isMySign = !!u.signed;
                  envelopeObj.link =
                   `/api/documents/sign/${actualDocument.projectId}/${actualDocument._id}?doc=${actualDocument.coreDocument}`
               }
           }
        } 
        if (isProjectAdmin) {
           envelopeObj.adminLink = actualDocument && currentProject && currentProject.signee && currentProject.signee.length &&
            `/api/documents/sign/${actualDocument.projectId}/${actualDocument._id}?doc=${actualDocument.coreDocument}&signee[]=` + currentProject.signee.join(`&signee[]=`)
        }

        const isCompanyAdmin = user.company?.knownCompanies[0].members?._general?.members.find(m => user._id === m._id)?.accessStatus > 0;
        const isApproveAgain = actualDocument.delApproved && actualDocument.delApproved.length && !!actualDocument.delApproved.find(i => i === user._id)

        const isCollaborative = inDoc && !displayVersion
            && (actualDocument.orig === 6 || actualDocument.orig === 7),
            counteractRequest = !actualDocument.blocked ? actualDocument.editorsContRequest: actualDocument.editorsRequest
        const isApprovedAll = approverUserList && approved && (approved.length === approverUserList.length)
        const isFinalizeInternal = inDoc && !displayVersion && actualDocument.orig === 9
        const isFinalizeExternal = inDoc && !displayVersion && actualDocument.orig === 10
        const isFinalizeRefuse = inDoc && !displayVersion && actualDocument.orig === 11
        const isFinalized = inDoc && !displayVersion && actualDocument.orig === 12
        const isCancellingFinalizeExternal = inDoc && !displayVersion && actualDocument.orig === 13
        const isCancellingFinalizeInternal = inDoc && !displayVersion && actualDocument.orig === 14
        const isInTemplate = window.location.pathname.includes('/template/')
        const isInTemplatesList = window.location.pathname.includes('/templates')
        const isDraftDoc = inDoc && actualDocument.orig === 302;
        const isDoc = inDoc && !actualDocument.contentType;
        const shared = inDoc && actualDocument.shared;

        if (inDoc && !displayVersion && approverUserList && ( !(actualDocument.blocked && !isCollaborative) || isFinalizeInternal || isFinalizeExternal ||
            isCancellingFinalizeExternal || isCancellingFinalizeInternal))  {

            // canApprove = approverUserList.findIndex((item) => item._id === user._id) !== -1;
            // approvedByMe = canApprove && (approved.indexOf(user._id) !== -1);
            //
            // if (isExternal) {
            //     canApprove = false
            //     approvedByMe = false
            // }
            // approveButton = !!approvedByMe
            //     ? <Button text='Approved'
            //               key='a1'
            //               mod='approved'
            //               callback={() => disapproveDocumentEditingAction(actualDocument._id)}/>
            //     : (!!canApprove
            //             ? <Button text={!!isApproveAgain ? 'Approve Again' : 'Approve'}
            //                       key='a2'
            //                       mod={!!isApproveAgain ? 'approveAgain' : 'approve'}
            //                       callback={() => approveDocumentEditingAction(actualDocument._id)}/>
            //             : (!!approverUserList.length ? <Button text='Approvals' mod='blue' key='a3'/> : '')
            //     );
        }

        const popup = {
            newVersion: {
                name: 'confirm',
                text: transS('Do you want to create a new version? ') +
                  transS('Note that you\'re not able to change the previous versions.'),
                confirm: {
                    name: transS('Confirm'),
                    event: () => {
                        saveNewDocumentSubVersionAction(actualDocument.coreDocument);
                    }
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            approve: {
                name: 'send',
                text: transS('You are about to send this document together with external comments to the other party.'),
                question: transS('Are you sure you want to send?'),
                confirm: {
                    name: transS('Send anyway'),
                    mod: 'blue arch',
                    event: () => {
                        if (actualDocument.version === 1) 
                          this.isDocChanged().then( () =>
                            getPopUpAction(popup.firstVersion) ).catch( () =>
                            transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate) );
                        else transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate);
                    }
                },
                cancel: {
                    name: transS('Wait'),
                    mod: 'blue fill',
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            transfer: {
                name: 'send',
                text: transS('You are about to send this document together with external comments to the other party.'),
                question: transS('Are you sure you want to send?'),
                coverMessage: {
                    editable: true,
                    event: 'SEND'
                },
                confirm: {
                    name: transS('Send'),
                    mod: 'blue fill send',
                    event: () => {
                        if (actualDocument.version === 1)
                          this.isDocChanged().then( () =>
                            getPopUpAction(popup.firstVersion) ).catch( () =>
                            transferDocumentEditingAction(actualDocument.coreDocument) );
                        else transferDocumentEditingAction(actualDocument.coreDocument);
                    }
                },
                cancel: {
                    name: transS('No'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            firstVersion: {
                name: 'confirm',
                header: transS('Show Tracked Changes?'),
                question: transL('fstDraft1'),
                text: transL('fstDraft'),
                confirm: {
                    name: transS('No, send clean'),
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate);
                    }
                },
                cancel: {
                    name: transS('Yes, show'),
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, true)
                    }
                },
                close: {
                    event: () => getPopUpAction(popUpReset)
                }
            },
            share: {
                name: 'send',
                lockRecepients: shared,
                header: transS(shared ? 'Revoking Access' : 'Sharing Document'),
                text: transL(shared ? 'revokeText' : 'shareText'),
                question: transS(shared ? 'Are you sure you want to revoke access?' : 
                  'Are you sure you want to share?'),
                coverMessage: shared ? null : {
                    editable: true,
                    event: 'SEND'
                },
                confirm: {
                    name: transS(shared ? 'Revoke' : 'Share'),
                    mod: shared ? 'red arch' :'blue fill send',
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, !shared);
                    }
                },
                cancel: {
                    name: transS('No'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            firstVersion: {
                name: 'confirm',
                header: transS('Show Tracked Changes?'),
                question: transL('fstDraft1'),
                text: transL('fstDraft'),
                confirm: {
                    name: transS('No, send clean'),
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate);
                    }
                },
                cancel: {
                    name: transS('Yes, show'),
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, true)
                    }
                },
                close: {
                    event: () => getPopUpAction(popUpReset)
                }
            },
            collaborate: {
                name: 'confirm',
                header: transS('Heads up!'),
                question: transS('Are you sure you want to start Collaboration Mode?'),
                coverMessage: {
                    editable: true,
                    event: 'COLLABORATE'
                },
                text: transS('You are about to send this document for collaboration together with external comments to the other party.'),
                confirm: {
                    name: transS('Collaborate'),
                    event: () => {
                        if (actualDocument.version === 1) getPopUpAction(popup.firstVersion);
                        else transferDocumentEditingAction(actualDocument.coreDocument, true);
                    }
                },
                cancel: {
                    name: transS('No'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },            
            collaborateCancel: {
                name: 'confirm',
                text: transS('Do you want to cancel collaborate with the document?'),
                confirm: {
                    name: transS('Confirm'),
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, true);
                    }
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            request: {
                name: 'confirm',
                header: transS('Request Back'),
                swap: true,
                text: transS('You can ask the other team to send you their version.')
                 + (counteractRequest ? 
                    transS('<br><br>Please note your team already sent a request on ')
                 + getDateString(counteractRequest,4, dateData) : ''),
                coverMessage: {
                    editable: true,
                    event: 'REQUEST_BACK'
                },
                confirm: {
                    name: transS('Request'),
                    event: () => {
                        requestDocumentEditingAction(actualDocument.coreDocument);
                        getPopUpAction(popUpReset);
                    }
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            overtake: {
                name: 'confirm',
                header: transS('Overtake Editing'),
                coverMessage: {
                    editable: true,
                    event: 'TAKE_OVER'
                },
                question: transS('Do you really want to take the control over editing?'),
                text: transL('over'),
                confirm: {
                    name: transS('Overtake'),
                    danger: true,
                    event: () => {
                        overtakeEditingAction(actualDocument.coreDocument);
                    }
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },

            // Send to finalize to the other team
            finalize: {
                name: 'confirm',
                header: transS('Finalise Document'),
                question: '',
                coverMessage: {
                    editable: true,
                    event: 'FINALIZE'
                },
                text: transL('final'),
                cancel: {
                    name: transS('Cancel'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Finalise')+'!',
                    mod: 'green fill',
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true);
                        getPopUpAction(popUpReset);
                    }
                }
            },
            finalizeNotProveAdmin: {
                name: 'confirm',
                header: transS('Finalise Document'),
                ads: 'approve',
                coverMessage: {
                    editable: true,
                    event: 'FINALIZE'
                },
                text: `${transL('final')}
                        <div class='h3' style="margin-top: 50px;margin-bottom: 20px">${transS('Approvals Needed')} 
                        (${ (approverUserList && approved) ? ` ${approverUserList.length - approved.length}/${approverUserList.length}` : ''})</div> 
                        ${transS('Before you send, export or finalise the document, you need it to be approved by:')}`,
                cancel: {
                    name: transS('Wait'),
                    mod: 'blue fill',
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                },
                confirm: {
                    name: transS('Finalise anyway!'),
                    mod: 'green arch',
                    event: () => {
                        transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true);
                        getPopUpAction(popUpReset);
                    }
                }
            },
            finalizeNotProve: {
                name: 'confirm',
                header: transS('Finalise Document'),
                ads: 'approve',
                text: `${transL('final')} 
                        <div class='h3' style="margin-top: 50px;margin-bottom: 20px">${transS('Approvals Needed')} 
                        (${ (approverUserList && approved) ? ` ${approverUserList.length - approved.length}/${approverUserList.length}` : ''})</div> 
                        ${transS('Before you can send you need the document to be approved by:')}`,
                cancel: {
                    name: transS('Got it'),
                    event: () => getPopUpAction(popUpReset)
                }
            }, // не используется

            // cancel finalize request
            cancelFinalize: {
                name: 'confirm',
                header: transS('Cancel Finalise'),
                text: transL('cancel fin'),
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Cancel Finalise'),
                    danger: true,
                    event: () => overtakeEditingAction(actualDocument.coreDocument)
                }
            },

            // Refuse finalize
            refuseFinalize: {
                name: 'confirm',
                header: transS('Refuse to Finalise the Document'),
                text: transL('refuseFin'),
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Refuse'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, false, false, true, true)
                }
            },

            // Send request to cancel the final version
            cancellingFinalVersion: {
                name: 'confirm',
                header: transS('Cancel Final Version'),
                text: transL('CancelFin'),
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Request'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true, false, true)
                }
            },

            // Cancel request to cancel finalize
            cancelRequestToCancelFinalize: {
                name: 'confirm',
                header: transS('Cancel Request'),
                text: transS(`Are you sure you want to cancel this request?`),
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Cancel Request'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true)
                }
            },

            // Confirm to cancel the final version
            confirmCancellingFinalVersion: {
                name: 'confirm',
                header: transS('Request to Cancel Final Version'),
                text: `${transS('Another team sent a request to cancel Final Version.')}
                        <br><br>
                    ${transL('ifConfirm')}`,
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Confirm'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true, false, true)
                }
            },
            confirmCancellingFinalVersionNotProve: {
                name: 'confirm',
                header: transS('Request to Cancel Final Version'),
                ads: 'approve',
                text: `${transS('Another team sent a request to cancel Final Version.')}
                        <br><br>
                        ${transL('ifConfirm')}${transF('ifConfirm')(approverUserList, approved)}`,
                cancel: {
                    name: transS('Got it'),
                    event: () => getPopUpAction(popUpReset)
                },
            }, // не используется
            confirmCancellingFinalVersionNotProveAdmin: {
                name: 'confirm',
                header: transS('Request to Cancel Final Version'),
                ads: 'approve',
                text: `${transS('Another team sent a request to cancel Final Version.')}
                        <br><br>
                        ${transL('ifConfirm')}${transF('ifConfirm')(approverUserList, approved)}`,
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Confirm anyway'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true, false, true)
                }
            },

            // Refuse to cancel the final version
            refuseCancelFinalVersion: {
                name: 'confirm',
                header: transS('Refuse to Cancel Final Version'),
                text: `${transS('Another team sent a request to cancel Final Version.')}
                        <br><br>${transL('ifRefuse')}`,
                cancel: {
                    name: transS('No'),
                    event: () => getPopUpAction(popUpReset)
                },
                confirm: {
                    name: transS('Refuse'),
                    danger: true,
                    event: () => transferDocumentEditingAction(actualDocument.coreDocument, this.state.toCollaborate, false, true)
                }

            },

            // docuSign
            initiateDocSign: {
                name: 'confirm',
                header: transS('Initiate signing the document with DocuSign'),
                text: `To initiate signing the document: <br/>
                       <ul style="list-style: none; padding: 0">
                            <li><span style="display: inline-block; margin-right: 24px;">1. </span>Click the green button below.</li>
                            <br/>
                            <li><span style="display: inline-block; margin-right: 20px; float: left; height: 40px">2. </span>Sign in or sign up with DocuSign. (Sometimes)</li>
                            <br/>
                            <li><span style="display: inline-block; margin-right: 20px; float: left; height: 40px">3. </span>Authorise Contract.one to send documents to DocuSign.</li>
                            <br/>
                            <li><span style="display: inline-block; margin-right: 20px; float: left; height: 60px">4. </span>When you are forwarded to a DocuSign setup page, customise fields for signing.</li>
                            <br/>
                            <li><span style="display: inline-block; margin-right: 20px; float: left; height: 40px">5. </span>On DocuSign, send the document to signees.</li>
                        </ul>`,
                confirm: {
                    name: transS('Send to DocuSign'),
                    mod: 'green fill',
                    link: envelopeObj.adminLink,
                    event: () => null
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => getPopUpAction(popUpReset)
                }
            }
        };

        const titleBlock = () => {
            if (isInTemplatesList) return
            if (isInTemplate || isDraftDoc) {
                const projectTitle = isInTemplate ? transS('Templates') : currentProject.title;
                const documentTitle = actualDocument.title
                const linkTo = isInTemplate ? '/templates' : `/#/${currentProject._id}`
                return <>
                  <div styleName='header-projectNameBlock'
                     style={!isTitleCut ? {marginRight: '40px'} : null}>
                    <NavLink 
                        to={linkTo} style={{width: projectNameWidth}}
                        onMouseDown={() => this.clearSelection()} >
                        <span styleName='header-projectName'
                              ref={(e) => {this.refProjectName = e}}>
                            {projectTitle}</span>
                    </NavLink>
                    <span styleName='header-titleSeparator'>/</span>
                    <span styleName={cx('header-documentName', {documentNameGrad: isTitleCut})}
                          style={{width: documentNameWidth}}
                          ref={(e) => {this.refDocumentName = e}}>
                        {documentTitle}</span>
                    {isHint && <div styleName='header-hintPlace'>
                        <div>
                            <NavLink
                                to={linkTo}>{projectTitle}</NavLink>
                            <span styleName='header-titleSeparator'>/</span>{documentTitle}
                        </div>
                    </div>}
                </div>
                <div className="borderHeader" />
              </>
            }

            if (!inProject || !inDoc) return
            const projectTitle = currentProject.title
            const documentTitle = actualDocument.title
            const linkTo = `/#/${currentProject._id}`
            return <>
              <div styleName='header-projectNameBlock'
                                               style={!isTitleCut ? {marginRight: '40px'} : null}>
                {isCollaborative && (!actualDocument.blocked && actualDocument.editorsRequest !== 0
                    || actualDocument.blocked && !!actualDocument.editorsContRequest || !!isApproveAgain)
                && <div styleName='header-collaborationMode'>{transS('Collaboration mode')}</div>}
                <NavLink to={linkTo} style={{width: projectNameWidth}}>
                        <span styleName='header-projectName'
                              ref={(e) => {this.refProjectName = e}}>
                            {projectTitle}</span>
                </NavLink>
                <span styleName='header-titleSeparator'>/</span>
                <span styleName={cx('header-documentName', {documentNameGrad: isTitleCut})}
                      style={{width: documentNameWidth}}
                      ref={(e) => {this.refDocumentName = e}}>
                        {documentTitle}</span>
                {isHint && <div styleName='header-hintPlace'>
                    <div>
                        <NavLink
                            to={linkTo + '/documents'}>{projectTitle}</NavLink>
                        <span styleName='header-titleSeparator'>/</span>{documentTitle}
                    </div>
                </div>}                
            </div>
            <div className="borderHeader" />            
          </>
        }

        const calcReviewedAndOpened = () => {
           let el = actualDocument.history.find(el => { return el.subVersion === 0});
           if (!el) return '';

           let s = 'Sent: '
           s += `${moment(el.createdAt).fromNow().replace(' ago','')} ago.`
           if (actualDocument.wasOpened) s += ' Opened ' + moment(actualDocument.wasOpened).fromNow();
           else s += ' Not opened yet'
           return transF('SentOpen')(s);
        }

        const panelControls = () => {
            if (!approverUserList && !isInTemplate) return // <- костыль чтобы не мигала кнопка approve при загрузке
            let isExportAllowed = hasDownloadDocumentPermission() || user.totalPaying && (user.ticks > 0);

            if (isInTemplate || isDraftDoc) {
                return <Templates />
            } else if (isCancellingFinalizeExternal && !isExternal || isCancellingFinalizeInternal && isExternal) {
                return <FinalizeCancelExternal approveButton={approveButton} approverUserList={approverUserList} isProjectAdmin={isProjectAdmin}
                                               approved={approved} isExternal={isExternal} actualDocument={actualDocument} approveBtnCallback={this.approveBtnCallback}
                                               getPopUpAction={getPopUpAction} popup={popup} isApprovedAll={isApprovedAll} approvers={this._approvers}
                                               copyDocument={this.copyDocument} copyDocumentBlocked={copyDocumentBlocked}/>
            } else if (isCancellingFinalizeExternal && isExternal || isCancellingFinalizeInternal && !isExternal) {
                return <FinalizeCancelInternal cancelRequest={() => getPopUpAction(popup.cancelRequestToCancelFinalize)} download={this.download}
                                               copyDocument={this.copyDocument} copyDocumentBlocked={copyDocumentBlocked}/>
            } else if (isFinalized) {
                return <Finalized copyDocument={this.copyDocument} copyDocumentBlocked={copyDocumentBlocked} download={this.download}
                                  internalUserList={internalUserList} externalUserList={externalUserList}
                                  envelopeObj={envelopeObj} currentProject={currentProject} envelope={envelope} user={user}
                                  popup={popup} getPopUpAction={getPopUpAction} isProjectAdmin={isProjectAdmin}/>
            } else if (isFinalizeExternal && isExternal || isFinalizeInternal && !isExternal) {
                return <FinalizedInternal setMenuAction={setMenuAction} stateMenu={stateMenu} frameProgress={frameProgress} jumpChanges={jumpChanges}
                                          getPopUpAction={getPopUpAction} popup={popup} calcReviewedAndOpened={calcReviewedAndOpened}
                                          openInvitePopup={this.sibebarTeamsClickHandler}/>
            } else if (isFinalizeExternal && !isExternal || isFinalizeInternal && isExternal) {
                return <FinalizedExternal approveButton={approveButton} stateMenu={stateMenu} frameProgress={frameProgress} approverUserList={approverUserList} isExternal={isExternal}
                                          approved={approved} approvers={this._approvers} jumpChanges={jumpChanges} setMenuAction={setMenuAction} actualDocument={actualDocument}
                                          isProjectAdmin={isProjectAdmin} getPopUpAction={getPopUpAction} popup={popup} openInvitePopup={this.sibebarTeamsClickHandler}
                                          approveBtnCallback={this.approveBtnCallback}/>
            } else if (isFinalizeRefuse && actualDocument.blocked) {
                return <FinalizeExternalRefuse getPopUpAction={getPopUpAction} popup={popup} isProjectAdmin={isProjectAdmin}/>
            } else if (displayVersion) {
                return <DisplayVersion actualDocument={actualDocument} approverUserList={approverUserList} displayVersion={displayVersion}
                                       isExportAllowed={isExportAllowed} approved={approved} approvers={this._approvers} isExternal={isExternal}
                                       isProjectAdmin={isProjectAdmin} user={user}
                                       stateMenu={stateMenu} tasksCount={tasksCount} frameProgress={frameProgress} setMenuAction={setMenuAction}
                                       getHistoryDocumentAction={getHistoryDocumentAction} tasksClickHandler={this.tasksClickHandler}
                                       freezeFunc={this.freezeFunc}
                                       download={this.download} jumpChanges={jumpChanges}/>
            } else if (actualDocument.blocked && !isCollaborative) {
                return <ReviewedExternal actualDocument={actualDocument} isProjectAdmin={isProjectAdmin} getPopUpAction={getPopUpAction} popup={popup}
                                         calcReviewedAndOpened={calcReviewedAndOpened} stateMenu={stateMenu} frameProgress={frameProgress}
                                         setMenuAction={setMenuAction} tasksCount={tasksCount} tasksClickHandler={this.tasksClickHandler}
                                         jumpChanges={jumpChanges} openInvitePopup={this.sibebarTeamsClickHandler}/>
            } else {
                return <ReviewedInternal actualDocument={actualDocument} isCollaborative={isCollaborative} approveBtnCallback={this.approveBtnCallback}
                                         setMenuAction={setMenuAction} approverUserList={approverUserList} getPopUpAction={getPopUpAction}
                                         popup={popup} jumpChanges={jumpChanges} isApprovedAll={isApprovedAll} isProjectAdmin={isProjectAdmin}
                                         isExternal={isExternal} approved={approved} approvers={this._approvers} user={user}
                                         setToCollaborate={value => this.setState({toCollaborate: value})} stateMenu={stateMenu}
                                         frameProgress={frameProgress} tasksCount={tasksCount} tasksClickHandler={this.tasksClickHandler}
                                         freezeFunc={this.freezeFunc}
                                         hasExternals={hasExternals} isApproveAgain={isApproveAgain} openInvitePopup={this.sibebarTeamsClickHandler}/>
            }
        };

        return <div style={{position: 'sticky', top: '0', left: '0', 
            zIndex: (showPayment || showExpired) ? '18': '8'}}>
            <div key="1" styleName='header-wrapper'>
            { inDoc && <div styleName='belowTop-frame-width'>
                   <div styleName='underComments-absolute'></div>
                </div>}
                <div styleName='header-wrapper-mask' />
                {( inDoc || !!user.company  || showSidebar || !inDoc) && // always sidebar 
                <div styleName={cx('sidebar-screen', {'_vis': isSideBarOpen || showSidebar})}>
                    { showSidebar ? <Account user={user}/> :
                    isSideBarOpen && <div styleName={'sidebar-wrapper'} ref={(e) => {this.refSidebar = e}}>
                        <LeftMenu sideBarOpenHandler={sideBarOpenHandler} />
                        {false && <SideBar download={this.download}
                                 user={user}
                                 toggleBar={this.versionBarOpenHandler}
                                 toggleNavigationVisAction={this.props.toggleNavigationVisAction}
                                 inDoc={inDoc && isDoc && !!frameProgress}
                                 isCompanyAdmin={isCompanyAdmin}
                                 isProjectAdmin={isProjectAdmin}
                                 isExportForbidden={false}
                                 isDocumentBlocked={actualDocument.blocked}
                                 isDocTransferToFinalize={isFinalizeExternal && !isExternal || isFinalizeInternal && isExternal}
                                 approversCount={isDoc && approverUserList && approverUserList.length}
                                 approvedsCount={isDoc && approved && approved.length}
                                 tasksCount={isDoc && tasksCount}
                                 projectName={currentProject.title}
                                 openInvitePopup={this.sibebarTeamsClickHandler}
                                 versionBarIsOpen={this.state.intVersionBarIsOpen}
                                 dragHistory={isDoc && frameProgress && frameProgress.dragHistory()}
                                 dragTasks={isDoc && frameProgress && frameProgress.dragTasks()}
                                 jumpDoc={(jumpId) => {frameProgress.jumpDoc(jumpId); sideBarOpenHandler(false)} }
                                 prohibitNewVersion={isDoc && frameProgress && frameProgress.prohibitNewVersion}
                                 actualDocument={actualDocument}
                                 renameDocumentAction={this.renameDocumentHandler}
                                 freezeFunc={this.freezeFunc}
                                 sideBarOpenHandler={sideBarOpenHandler}
                                 setPopupMode={() => { setPopupMode(true); this.sideBarOpenHandler(false) } }
                            />}
                    </div>
                    }
                </div>
                }

                <header styleName='header'>
                    <div className='scaffold'></div>
                    <div styleName="logoplace" 
                      onMouseDown={() => this.clearSelection()}>  
                          <Logo />  
                    </div>
                    
                    {user.temporary && !inDoc || (firstTime === 2) && projectList && !projectList.length
                    ? <div styleName='header-sideBarButton'></div>
                    : <div styleName='header-sideBarButton' onClick={() => sideBarOpenHandler(!isSideBarOpen)}>
                        <img
                            src={isSideBarOpen ? "../../../../images/burgerMenuOpen.png" : "../../../../images/burgerMenu.png"}
                            alt="SideBar button"/>
                    </div> }
                    {!inDoc && projectList && !projectList.length 
                       && !window.location.pathname.includes('/templates')
                       && <div styleName='header-noProjectsText'>
                        {transS('There are no projects yet')}</div>}
                    {titleBlock()}

                    {(inDoc || isInTemplate) ? panelControls()
                      : !isDocument && <TopMain />}

                    {!!isDocument && <TopDocMenu /> }

                    {(inDoc || isInTemplate || isDocument) ? false : <TopMainLine />}

                    <BrowserSupportNotice />
                    {user.temporary ?
                        <TempUserBlock />
                        : isDocument ? false :
                        <div styleName='header-userLogo'>
                            <UserLogo firstname={user.firstname} editable lastname={user.lastname} avatar={user.avatar} mod='_pointer'/>
                        </div>}
                </header>

                {(isInTemplate || actualDocument.orig === 302) ?
                    <TemplatesUnderMenu hideVars={actualDocument.orig !== 1} />
                : !!inDoc && <DocControlsUnderMenu />    
                }

                {!!isDocument && <DocControlsUnderMenu /> }

            </div>
            <NoticeBanner />
            {(showPayment || showExpired) && <Payment user={user}/>}

            { invitePopup && invitePopup.show && invitePopup.showInSidebar 
              && <ProjectPage inSideBar={true}/> }
        </div>
    }
}

export default TopMenu
