import React, { useRef, useEffect, useState } from 'react';
import {useLocation, useHistory, useParams} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import CSSModules from 'react-css-modules';
import cx from 'classnames';
import styles from './frameDoc.css'
import { transS } from '../../../../services/helpers/lang';
import { Icon_ArrowDown, Icon_CheckBlank, Icon_Checked, Icon_Doc, Icon_Folder } from '../../../../components/svglib/svglib';
import { TreeSimple } from '../../../../components/TreeNode';
import { colors, fontSize } from '../../../../components/colors';
import OverlineMenu from '../paragraphList/OverlineMenu';
import api from '../../../../services/api/api';
import { setDisplayOnlyAction, setDocumentNavigationAction, setVarChange } from '../../../../redux/document/actions';
import Clause from '../clauseLib/Clause';
import DateControl from '../../components/dateControl/DateControl';
import Spinner from '../../../../components/spinner/Spinner';
import { afterFrameLoad, helperInfo, initActions, sendToFrame,
  setFrameParams,  setReadingCheckInterval } from './frameDocHelper';
import { getPopUpAction } from '../../../../services/actions/dataActions';
import { blockParagraphsAction } from '../../../../redux/paragraph/actions';
import { unitConstants } from '../../../../services/reducers/unitReducer';
import { iframeSetContent } from '../../../../frame/initFrame';
import Ruler from '../paragraphList/Ruler';

const spinner = () => 
<div style={{position: 'fixed', width: '100%', height: '100%', 
 top: '0', left: '0', background: 'white'}}><Spinner /></div>;

const FrameDocView = props => {
  const 
    dispatch = useDispatch(),
    location = useLocation(),
    history  = useHistory(),
    {docUnitId, versionDoc} = useParams(),

    {isDocument, unitsStruc, luserId} = useSelector(state => state.unitReducer),
    {communicationVis, popupMode} = useSelector(state => state.paragraphReducer),
    {user, dateData, editClause} = useSelector(state => state.userReducer),
    {dateControl} = useSelector(state => state.document),

    [docResponse, setDocResponse] = useState(null),
    [error, setError] = useState(false),
    [docReady, setDocReady] = useState(false),
  

    [versionBarIsOpen, setversionBarIsOpen] = useState(false),
    [selectedTime, setSelectedTime] =  useState(6),
    [paraFilter, setParaFilter] = useState( 2),
    [sideFilter, setSideFilter] = useState( 2),
    [docStatsError, setDocStatsError] = useState( false),
    [docStats, setDocStats] = useState( null),
    [isFrameVis, setIsFrameVis] = useState( true),
    [lastEventTimestamp, setLastEventTimestamp] = useState( 0), // event: mousemove || scroll
    [overlineParams, setOverlineParams] = useState( {}),
    [jumpToCommunicationMessage, setJumpToCommunicationMessage] = useState( null),
    [dateVar, setDateVar] = useState(null), 
    [varlist, setVarList] = useState(null), 
    [editClauseY, setEditClauseY] = useState(0),

    navigationExists = !popupMode && isDocument?.draft === null 
      && isDocument?.orig !== 302
  
  
  const _dateCallback = date => {
    if (date) {
        const thatVar = varlist.find(el => el.id === dateVar.id)
        thatVar.value = date
        api.updateTemplateUnit(isDocument.loc_id, 'c1 send vars', 
          undefined, isDocument.loc_id, varlist);
        dispatch(setVarChange(varlist))
        sendToFrame({c1: 'variable', action: 'list', varlist})        
    }
    setDateVar(null)
    setVarList(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =
          versionDoc === undefined
            ? await api.getUnitDocument(docUnitId)
            : await api.getUnitDocumentVersion(docUnitId, versionDoc);
        setDocResponse(response.data)
      } catch (err) {
        setError(err)
      }
    };
    fetchData();
  }, []);
  
  helperInfo({setDocReady})
  useEffect(() => initActions(
    {location, history, dispatch, 
      user, docUnitId,  
      popupMode, setOverlineParams, setDateVar, setVarList}
  ), [])

  useEffect( () => {
    if (!docResponse)
      return
    const {content, locks, redos} = docResponse
    iframeSetContent(content, location.pathname.includes('/template/'))
    setFrameParams({isDocument, locks, redos})
    afterFrameLoad()
  },[docResponse])

  useEffect(() => sendToFrame({c1: 'dateData', dateData}), [dateData])
  useEffect(() => editClause && setEditClauseY(
    Math.round(editClause.shift + 382 - 114 +scrollY)), editClause)

  return (
  <div styleName={cx('wrapper', {'popupMode': popupMode})}>
    <div styleName={cx({'popupModeWindow': popupMode})}>
      <div styleName='rulerWrapper'>
        {communicationVis == 2 && <Ruler />}
      </div>
      { /*popupMode && <span onClick={ () => this.setPopupMode(false) } className='close-cross'>+</span>}
      {popupMode && <DocStats filter={this.statsFilterClickHandler} selectedTime={selectedTime} paraFilter={paraFilter} sideFilter={sideFilter}
                                setFrameVis={ value => this.setState({isFrameVis: value}) }
                                isProjectAdmin={currentProject.admins && !!currentProject.admins.find(i => i === user._id)}
                                actualDocument={actualDocument} docStats={this.state.docStats} isError={docStatsError} user={user} />}
    */}
      {navigationExists && false /*
        <DocumentNavigation 
          jump={ (id, isFromBackLink) => 
            this._jumpDoc(id, actualDocument._id, null, null, null, isFromBackLink)}/>
          */}
      <div styleName='preframe'>
        {docReady && <OverlineMenu params={overlineParams} /> }
              
        {!!(editClause || dateControl) && 
          <div styleName='clauseScreen' 
                onClick={() => {editClause.modifyClause(null);
                    _dateCallback(null)}}></div>}                 

        {!!editClause && <Clause 
          kind={editClause.kind || "clauseDraft"}
          clause={editClause.clause}
          callback={editClause.modifyClause}
          shift={editClauseY}
          rOnly={editClause.rOnly}
        />}

        {!!dateControl?.fromParagraph 
          && <DateControl callback={this._dateCallback}/>}
      </div>

      <iframe width="100%" height="15000" name='iframe' src='/templates/frame.html'
        style={ ( (popupMode && !docStats && !docStatsError) || !isFrameVis) ? 
          {display: 'none'} : {} }
        styleName='iframe' />

      { !docReady && spinner() }

    </div>
  </div>)
}

export default new CSSModules(FrameDocView, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
