import React, { useState, useEffect, useRef } from "react";

const ResponsiveMenu = ({ items, gap = 40 }) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [overflowItems, setOverflowItems] = useState([]);
  const [isOverflowMenuOpen, setOverflowMenuOpen] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const updateMenu = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const children = Array.from(containerRef.current.children);
      let totalWidth = 0;
      const visible = [];
      const overflow = [];

      children.forEach((child, index) => {
        const itemWidth = child.offsetWidth;
        if (totalWidth + itemWidth + gap * visible.length <= containerWidth) {
          visible.push(items[index]);
          totalWidth += itemWidth;
        } else {
          overflow.push(items[index]);
        }
      });

      setVisibleItems(visible);
      setOverflowItems(overflow);
    };

    updateMenu();
    window.addEventListener("resize", updateMenu);
    return () => window.removeEventListener("resize", updateMenu);
  }, [items, gap]);

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        alignItems: "baseline",
        overflow: "hidden",
        gap,
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap,
          flexGrow: 1,
        }}
      >
        {visibleItems.map((item, index) => (
          <div key={index} style={{ display: "flex", alignItems: "baseline" }}>
            {item}
          </div>
        ))}
      </div>

      {overflowItems.length > 0 && (
        <div style={{ position: "relative" }}>
          <button
            onClick={() => setOverflowMenuOpen(!isOverflowMenuOpen)}
            style={{
              padding: "8px 16px",
              background: "#f3f3f3",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            ...
          </button>

          {/* Контейнер с overflow-элементами */}
          <div
            style={{
              position: "absolute",
              top: 0,
              right: isOverflowMenuOpen ? "0" : "-300px", // Задвигание и выезд
              display: "flex",
              gap,
              background: "#ffffff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "8px",
              borderRadius: "4px",
              zIndex: 10,
              height: "100%",
              transition: "right 0.3s ease-in-out", // Анимация
            }}
          >
            {overflowItems.map((item, index) => (
              <div key={index} style={{ display: "flex", alignItems: "baseline" }}>
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveMenu;
