import React, { useState, useRef } from 'react';
import { ButtonLarge } from '../../../../components/button/Buttons';
import { colors } from '../../../../components/colors';
import { Icon_Doc, Icon_Doc_Auto, Icon_Folder } from '../../../../components/svglib/svglib';
import { transS } from '../../../../services/helpers/lang';
import { UniPopup } from '../../../../components/popupWindows/UniPopup';
import { newFolderDialog, newUnitDialog } from './UnitsHelper';
import { unitFromLocation } from '../../../../services/sockets/directStore';
import { getRelativePosition } from '../../../../services/helpers';
import { useSelector } from 'react-redux';

const dashedStyle = {borderColor: colors.primary,
    backgroundColor: colors.white,
    color: colors.primary,
    borderStyle: "dashed"},
  orangeStyle = {backgroundColor: colors.orange,
    borderColor: colors.orange,
    color: colors.white}
  

export const ButtonBlock = () => {
  const [showPopup, setShowPopup] = useState(null),
    ref=useRef()
  const {luserId} =  useSelector(state => state.unitReducer)
  return (
    <div style={{margin: "50px 0", position: "relative",
        display: "flex", gap: "20px"}} ref={ref}>
    <ButtonLarge 
        style={orangeStyle}
        Picture={Icon_Doc_Auto}
        text={transS('Create Standard Document')}
    />
    <ButtonLarge 
        style={dashedStyle}
        Picture={() => <Icon_Doc color={colors.primary} />}
        text={transS('Add Document')}
        action={(event) => {
          const unit = unitFromLocation(),
            {position, centered} = getRelativePosition(event, ref.current)
          unit?.externals ?
            null
          : newUnitDialog(unit, luserId, centered, 'doc', setShowPopup)
        } }
    />
    <ButtonLarge 
        style={dashedStyle}
        Picture={() => <Icon_Folder color={colors.primary} />}
        text={transS('Add Folder')}
        action={(event) => {
          const unit = unitFromLocation(),
            {position, centered} = getRelativePosition(event, ref.current)
          unit?.externals ?
            newFolderDialog(unit, position, setShowPopup)
          : newUnitDialog(unit, luserId, centered, 'folder', setShowPopup)
        } }
    />
    {!!showPopup && <UniPopup {...showPopup} />}
    </div>)
}