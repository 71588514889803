import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL } from '../../../../services/helpers/lang';

const ShowTinyStatus = ({status, statusRefine}) => {   
    return <div className='dev-button-std' styleName={statusRefine || status}>
          {transL(status)}
    </div>
}
export default CSSModules(ShowTinyStatus, styles, {allowMultiple: true});  