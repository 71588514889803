import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { colors, fontSize } from '../../../../../../components/colors'
import { transS } from '../../../../../../services/helpers/lang'

export const LeftNavigation = () => {
  const {user} = useSelector(state => state.userReducer),
   isCompanyAdmin = user.company?.knownCompanies[0].members._general.members.find(
      m => user._id === m._id)?.accessStatus > 0

return (
  <div style={styleContainer}>
    <div style={styleHeader}>
      {transS('Additional Functions')}
    </div>
    <NavLink to="/templates" style={styleNav}>
      {transS('Templates')} </NavLink>
    <NavLink to="/clauses" style={styleNav}>
      {transS('Clause Library')} </NavLink>
    <NavLink to="/policies" style={styleNav}>
      {transS('Policies')} </NavLink>
    {!!isCompanyAdmin && <NavLink to="/company" style={styleNav}>
      {transS('Company Administration')} </NavLink> }
  </div>)
}

const styleContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}, styleHeader = {
  fontSize: fontSize.h4,
  fontWeight: 'bold',
  color: colors.gray,
  marginBottom: 10,
}, styleNav = {
  fontSize: fontSize.standart,
  color: colors.primary,
  cursor: 'pointer',
}