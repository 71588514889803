import React from 'react';
import { rights } from '../../../../../configs/appConfig';
import { transS, transF } from '../../../../services/helpers/lang';
import { Icon_ArrowDown, Icon_Bin, Icon_Cog, Icon_Doc, Icon_Doc_Auto, Icon_Download, Icon_Folder, Icon_Pin, Icon_Pinned } from '../../../../components/svglib/svglib';
import { colors } from '../../../../components/colors';
import { ButtonStd } from '../../../../components/button/Buttons';
import { getFolderNewName, justifyNewUnit, makeNewUnit, prepareFileList, unitHelper, unitNewChildTeams } from './unitsFuncs';
import { addUnit, changeUnit, deleteUnit, expandUnit, movePrivateUnit, pinUnit } from '../../../../services/sockets/directStore';
import api from '../../../../services/api/api';
import { MoveFolder } from './MoveFolder';
import { StdDrop } from '../../../../components/StdDrop';
import { StdRadio } from '../../../../components/StdRadio';
import { Settings } from './Settings';
import { SelectTeams } from './SelectTeams';
import { getRelativePosition } from '../../../../services/helpers';
import { TeamMember } from './TeamMember';

const icon_arrow = () => <Icon_ArrowDown color={colors.primary} />,
  icon_doc_auto = () => <Icon_Doc_Auto color={colors.primary}/>,
  icon_doc = () => <Icon_Doc color={colors.primary}/>,
   icon_folder = () => <Icon_Folder color={colors.primary}/>


export const getContextItems = (unit, luserId, position, centered, setState, isUnitPinned) => {
  const disabled = !(unit.integrated & rights.CAN_REMOVE),
    {iAmInternal, inGroup, isDoc, isFolder, checkApprove} = unitHelper(unit, luserId),
    downloadable = isDoc & unit.orig != 302 || unit.contentType,
    isMyfolder = unit.externals && unit.loc_id !=='_externals',
    canRename = !disabled || isMyfolder

  return [
    {text: transS(
      "Rename"),
      action: (event) => canRename && renameUnitDialog(unit, position, setState), 
        disabled: !canRename},

    unit.movable ? {
      text: transS(
        "Select personal folder"),
      action: (event) => moveUnitDialog(unit, centered, setState),
    } : null,

    unit.externals ? null : {text: transS(
      "Parameters & Settings"), Picture: Icon_Cog, 
      action: () => editParams(unit) },

    unit.externals ? null : {text: '1', line: true},

    !isUnitPinned && unit.folders?.length ? {text: transS(
      "Show All and Expand"), 
      Picture: icon_arrow,
      action: () => expandUnit(unit.loc_id, !!unit.mark)} : null,

    unit.externals ? null :
    {text: transS(isUnitPinned ? 
      "Unpin" : "Pin"), 
      Picture: isUnitPinned ? Icon_Pinned : Icon_Pin, 
      action: () => pinUnit(unit) },

    downloadable ? {text: transS("Download"), Picture: Icon_Download, 
      action: () => api.downloadUnit(unit.loc_id) } : null,

    {text: '2', line: true},

    isUnitPinned ? null : 
      {text: transS(!isFolder ? "For this document" : "For this folder"), 
      style: {color: colors.darkGray}},

    isUnitPinned || unit.externals ? null : 
      {text: transS("New Document from Template"), Picture: icon_doc_auto,  
      action: () => newTemplate(unit)},

    isUnitPinned || unit.externals ? null : 
      {text: transS("New Document"), Picture: icon_doc, 
      action: () => unit?.externals ?
      newFolderDialog(unit, position, setState)
      : newUnitDialog(unit, luserId, centered, 'doc', setState)
    } ,

    isUnitPinned ? null : 
      {text: transS(unit.externals ? "New Personal Folder" :  "New Folder"), 
      Picture: icon_folder, 
      action: () => unit?.externals ?
        newFolderDialog(unit, position, setState)
        : newUnitDialog(unit, luserId, centered, 'folder', setState)
      },

    {text: transS(!isFolder ? "Delete Document" : "Delete Folder"), 
      style: {color: colors.darkRed},
      action: () => canRename && deleteUnitDialog(unit, centered, isFolder, setState), 
        disabled: !canRename},
  ]
}

const renameUnitDialog = (unit, position, setState) => setState({
  name: 'uni',
  setState,
  position, crossed: true,
  items: [
    ()=><div className='h3'>{transS('Rename')}</div>,
    ()=><input id='renameUnitInput' className='dev-std-input'
       defaultValue={unit.title} />,
    ()=><div className='dev-std-buttons-block'>
      <ButtonStd text={transS('Ok')} 
        action={()=>{
          const newName = document.getElementById('renameUnitInput').value.trim()
          if (newName || newName !== unit.title)
            if (unit.externals)
              changeUnit({loc_id: unit.loc_id, title: newName})
            else
              api.renameUnit({loc_id: unit.loc_id, title: newName})
          setState(null)
      }}
      />
      <ButtonStd text={transS('Cancel')} 
        action={() => setState(null)}
        style={{
          backgroundColor: colors.white,
          color: colors.primary,
      }}/>
    </div>
  ]
})
  

  const editParams = unit => {

  }

  export const newFolderDialog = (unit, position, setState) => setState({
    name: 'uni',
    setState,
    position, crossed: true,
    items: [
      ()=><div className='h3'>{
        unit ?
        transS('New Folder at ') + unit.title
        : transS('New Folder ')
      }</div>,
      ()=><input id='renameUnitInput' className='dev-std-input'
         defaultValue="" />,
      ()=><div className='dev-std-buttons-block'>
        <ButtonStd text={transS('Ok')} 
          action={()=>{
            const name = document.getElementById('renameUnitInput').value.trim()
            if (name)
                addUnit({loc_id: 'e'+(Date.now()), integrated:0, folder: name, 
                  parentId: unit.loc_id, externals: true})
            setState(null)
        }}
        />
        <ButtonStd text={transS('Cancel')} 
          action={() => setState(null)}
          style={whiteButton}/>
      </div>
    ]
  })

  const whiteButton = {
    backgroundColor: colors.white,
    color: colors.primary,
  }, redButton = {
    backgroundColor: colors.white,
    color: colors.darkRed,
    borderColor: colors.white
  }, whiteButtonW = {
    backgroundColor: colors.white,
    color: colors.primary,
    borderColor: colors.white
  }

  const settingsStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: 'flex-start'
  }

  export const newUnitDialog = (unit, luserId, centered, kind, setState) => {
    let fileList = null, locationSelected = unit ? 1 : 0, showExtra,
      newUnit = makeNewUnit(unit, luserId)
    const onFilesChange = list => fileList = list,
      onLocSelect = i => {
        locationSelected = i
        if (i) showExtraDialog(0)
      },
      settingsCallback = data => {
        newUnit = data
      },
      items = [
        ()=><div className='h1'>{
          transS(kind === 'folder' ? 'Add Folder' :
            kind === 'doc' ? 'Add Document' : 'Create Standard Document')
        }</div>,
        () => <StdDrop onFilesChange={onFilesChange} />,
        () => <div style={settingsStyle}>
          <StdRadio 
            selections={[
              transS(kind === 'folder' ? 'Save As New Folder' : 'Save As New Document'),
              transS('Select Location')]}
            selected={locationSelected}
            callback={onLocSelect}
            callForever="1"
          />
          <ButtonStd text={transS('Add Companies, Teams, Parameters & Settings')} 
            Picture={Icon_Cog}
            style={whiteButtonW}
            action={() => showExtraDialog(1)}
          />
        </div>,
        ' ',
        ()=><div className='dev-std-buttons-block'>
          <ButtonStd text={transS('Done')}
            style={whiteButton} 
            action={()=>{
              // process fileList, locationSelected, settings
              // settings are 
              
              let inputId = document.getElementById('renameUnitInput'),
                name = inputId ? inputId.value.trim() : ''
              if (inputId && !name)
                name = getFolderNewName(unit?.loc_id)                

              if (unit?.externals) {
                addUnit({loc_id: 'e'+(Date.now()), integrated:0, folder: name, 
                  parentId: unit.loc_id, externals: true})
                setState(null)
              } else {
                justifyNewUnit(newUnit)
                prepareFileList(fileList).then(res => fileList = res)
                .catch(err => fileList = null)
                .finally(() => {
                  teamsShowResult(() => api.addUnit(
                    {folder: name, newUnit, fileList}), setState)
                })                
              }              
            }}
          />
          <ButtonStd text={transS('Delete')} 
            action={() => setState(null)}
            Picture={Icon_Bin}
            style={redButton}/>
        </div>
      ],
      showExtraDialog = (selected) => {
        if (showExtra) return
        setState({
          name: 'uni', centered,
          setState, 
          crossed: true,
          items,
          extra: () => <Settings 
            unit={unit}
            luserId={luserId}
            startSelectedItem={selected}
            startEdited={newUnit}
            callback={settingsCallback}
          />
        })
      }

    if (kind === 'folder') {
      items.splice(1,0,
        ()=><input id='renameUnitInput' className='dev-std-input'
           placeholder={transS('Enter Name')} defaultValue="" />
      )
    }

    setState({
    name: 'uni', centered,
    setState,
    crossed: true,
    items
  })
  }

  const newTemplate = unit => {

  }

  export const deleteUnitDialog = (unit, centered, isFolder, setState) => setState({
    name: 'uni', centered,
    setState,
    crossed: true,
    items: [
      ()=><div className='h3'>{transS(
        isFolder ? 'Do you want to remove the folder?' 
        : 'Do you want to remove the document?')
        .replace('?', unit.title ? ` (${unit.title})?` : '?')}
      </div>,
      ()=><div className='dev-std-buttons-block'>
        <ButtonStd text={transS('Cancel')} 
          action={()=>setState(null)}
          style={{
            backgroundColor: colors.white,
            color: colors.primary,
        }}
        />
        <ButtonStd text={transS('Ok')} 
          action={() => {    
            if (unit.externals)
              deleteUnit({parent: unit.loc_id, ids: [unit.loc_id]})
            else
              api.deleteUnit(unit.loc_id)
            setState(null)}
          }
          style={{
            backgroundColor: colors.white,
            color: colors.darkRed,
            borderColor: colors.darkRed,
        }}/>
      </div>
    ]
  })

  const moveUnitDialog = (unit, centered, setState) => setState({
    name: 'uni',  centered,
    setState,
    crossed: true,
    items: [
      ()=><div className='h3'>{transS('Select personal folder')}
        </div>,
      ()=><MoveFolder unit={unit} 
        action={changed => {
          if (changed !== null)
            movePrivateUnit(unit, changed)
          setState(null)
        }}
      />
    ]
  })

  export const tryDownloadDialog = (unit, setState) => {
    const {centered} = getRelativePosition()
    return ({
      name: 'uni',
      setState,
      centered,
      crossed: true,
      items: [
        transS("Document content can't be converted to pdf. You can download it"),
        ()=><div className='dev-std-buttons-block'>
          <ButtonStd text={transS('Download')} 
            action={()=> {
              api.downloadUnit(unit.loc_id)
              setState(null)
             } }
          />
          <ButtonStd text={transS('Cancel')} 
            action={() => setState(null)}
            style={{
              backgroundColor: colors.white,
              color: colors.primary,
          }}/>
        </div>
      ]
    })
  }

  export const unitTeamsDialog = (unit, setState) => {
    let result // {adds: [], removes: [], changes:[]
    const action = (data => {result = data})
    const saveResult = (setState) => {     
      if (!result)
        return setState(null)
      result.loc_id = unit.loc_id
      teamsShowResult(() => api.changeTeams(result), setState)
    }
    const {centered} = getRelativePosition()
    return ({
      name: 'uni',
      setState,
      centered,
      fixWidth: 480, 
      crossed: true,
      items: [
        ()=><div className='h3'>{transS('Teams')}</div>,
        ()=> <SelectTeams 
          unit={unit}
          action={action}
        />,
        ()=><div className='dev-std-buttons-block'>
          <ButtonStd text={transS('Ok')} 
            action={()=> saveResult(setState) }
          />
          <ButtonStd text={transS('Cancel')} 
            action={() => setState(null)}
            style={{
              backgroundColor: colors.white,
              color: colors.primary,
          }}/>
        </div>
      ]
    })
  }
    
  const teamsShowResult = (func, setState) => {
    const {centered} = getRelativePosition()
    const uni = {
      name: 'uni',
      setState,
      centered,
      crossed: true,
      items: [
        ()=> <div className='dev-std-buttons-block'>
          <ButtonStd text={transS('Ok')} 
            action={()=> setState(null) }
          />
        </div>
      ]
    }
    let newState = null
    func()
      .then(res => {
        if (!res.data.data)
          return 
        const {failed} = res.data.data
        if (!failed || failed.blank)
          return 
        newState = uni
        if (failed.docs.length) {
          failed.docs.forEach(doc => 
            uni.items.unshift(doc.name) )
          uni.items.unshift(transS('Failed to import documents:'))
        }
        if (failed.teams.length + failed.users.length) {
          failed.teams.forEach(t => uni.items.unshift(() => <TeamMember 
            key={t}
            luserId={t}
          />))
          failed.users.forEach(t => uni.items.unshift(() => <TeamMember 
            key={t}
            luserId={t}
          />))
          uni.items.unshift(transS('You cannot add a user from an internal team to an external team'))
        }
      })
      .catch(err =>{
        uni.items.unshift(transS('Operation Error'))
        newState = uni
        console.log('api.changeTeams ', err)
      })
      .finally( () => setState(newState)  )
  }