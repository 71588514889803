import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { colors, fontSize } from "../../../../components/colors";
import {NavLink, Redirect} from 'react-router-dom';
import { Icon_ArrowDown } from "../../../../components/svglib/svglib";
import { element } from "prop-types";

const DocumentMenuTitle = () => {
  const [isExpanded, setExpanded] = useState(false),
    [overflowed, setOverflowed] = useState(null),
    [truncatedTitle, setTruncatedTitle] = useState(''),
    {isDocument, unitsList, luserId} = useSelector(state => state.unitReducer),
    optimistic = useRef(),
    forCalcs = useRef()

  const parent = unitsList.get(isDocument.ancestor), 
    twoLines = 33
  

  const wrapperStyle = {
    display: "flex",
    gap: 10,
    alignItems: "baseline",
    fontSize: fontSize.standart,
  },
  containerStyles = {
    maxWidth: "200px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    color: colors.darkGray,
    whiteSpace: "normal",
  }, absoluteStyles = {
    position: "absolute",
    left: -4000,
  }, topLevelStyle = {
    color: colors.gray,
    cursor: "pointer",
  }, truncatedStyles = {
    width: "180px",
  }, overlayStyles = {
    position: "absolute",
    top: -20,
    left: -2,
    width: 240,
    background: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    padding: 20,
    zIndex: 10,
  };


  useLayoutEffect(() => {
    if (!optimistic.current) 
      return
    setOverflowed(optimistic.current.getBoundingClientRect().height > twoLines)
  }, [parent?.title, isDocument.title])

  useEffect(() => {
    if (!overflowed) return
    const s = isDocument.title, element = forCalcs.current
    let trunc = s
    element.innerText = s
    while (element.getBoundingClientRect().height > twoLines) {
      element.innerText = trunc + '...'
      trunc = trunc.slice(0, -1)
    }
    setTruncatedTitle(trunc === s ? s : (trunc + '...'))
  }, [overflowed])

  const FullTitle = ({top, title}) => <>
    {!!top && <NavLink to={`/units/${parent.loc_id}`} style={topLevelStyle}>
        {top} /
    </NavLink>}
    <div>{title}</div>
  </>
  

  return (
    <div style={wrapperStyle}>
      <div style={{
          ...containerStyles,
          ...(isExpanded ? {} : truncatedStyles),
        }}
        onClick={() => overflowed && setExpanded((prev) => !prev)}
      >
        {overflowed === null ? ' ':
          overflowed === false 
          ? <FullTitle top={parent?.title} title={isDocument.title} />
          : truncatedTitle
        }

        <div style={{...containerStyles, ...absoluteStyles}} ref={optimistic}>
          <FullTitle top={parent?.title} title={isDocument.title} />
        </div>

        {!!overflowed && <div 
          style={{...containerStyles, ...absoluteStyles, ...truncatedStyles}} 
          ref={forCalcs}>            
        </div>}
      </div>
      <span style={{cursor: 'pointer'}} onClick={() => setExpanded(true)}>          
        {!!overflowed && <Icon_ArrowDown />}
      </span>
      {isExpanded && (
        <div style={overlayStyles}>
          <div style={wrapperStyle}
            onClick={() => setExpanded(false)}>
            <div style={{
              ...containerStyles, ...truncatedStyles,}} >
              <FullTitle top={parent?.title} title={isDocument.title} />
            </div>  
            <span style={{cursor: 'pointer'}}>          
              <Icon_ArrowDown flip={true}/> 
            </span>       
          </div>          
        </div>
      )}
    </div>
  );
};

export default DocumentMenuTitle;
