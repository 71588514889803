import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './clauseLib.css';
import { transL, transS } from '../../../../services/helpers/lang';
import Button from '../../../../components/button/Button';
import Spinner from '../../../../components/spinner/Spinner';
import ListHeader from "../../components/ListHeader/ListHeader";
import Clause from './Clause';
import ShowCategories from './ShowCategories';
import { getDateString } from '../../../../../common/getDateString';

const ClauseLib = ()  => {
  const {user, clauseLib, knownUsers, dateData} 
    = useSelector(state => state.userReducer)
  const refFind = useRef();
  const [findNotFound, setFindNotFound] = useState(false);
  const [founded, setFounded] = useState(null)
  const [viewVars, setViewVars] = useState("All");
  const checkFuncs = {"All": () => 1,
    "Drafts": c => c.isDraft, "Published": c => !c.isDraft}
  const [openedFolders, setOpenFolders] = useState(new Set())
  const [filteredFolders, setFilteredFolders] = useState(null)
  const [srchPattern, setSrchPattern] = useState(null)
  const [collapsed, setCollapse] = useState('collapsed');
  const [makeNew, setMakeNew] = useState(null)
  const [editClause, setEditClause] = useState(null)
  

  const newClause = () => !makeNew && setMakeNew('clause')
  
  const newFolder = () => !makeNew && setMakeNew('folder')

  const setAll = (NotFound, FilteredFolders, SrchPattern, Founded) => {
    setFindNotFound(NotFound)
    setFilteredFolders(FilteredFolders)
    setSrchPattern(SrchPattern)
    setFounded(Founded)
  }

  const expandAll = isExpand => {
    const s = new Set()
    if (isExpand)     
      clauseLib.folders.forEach(f => traverseFolders(f,
        folder => s.add(folder.id)))
    setOpenFolders(s)
  }
    
  const checkFindFilter = el => {    
    if (el && el.childElementCount) {
      el.innerHTML = el.textContent
      el.blur()
    }
    let foundFolders = false
    const found = new Set(), badFolders = new Set(),
      pattern = refFind.current.textContent.trim().toLowerCase(),
      check = checkFuncs[viewVars]

    if (!pattern) 
      return setAll(false, null, null, null)
    
    clauseLib.folders.forEach(f => traverseFolders(f,
      folder => { if (!folder.title.toLowerCase().includes(pattern))
       badFolders.add(folder.id)
       else foundFolders = true
    }))

    clauseLib.clauses.forEach(c => {
      if (check(c) && 
        (c.title+'$'+c.hint+'$'+c.content).toLowerCase().includes(pattern))
        found.add(c.id)      
    });
    if (found.size || foundFolders) {
      clauseLib.folders.forEach(f => traverseFolders(f,
        (folder, callStack) => {
            const isGood = !badFolders.has(folder.id)
            if (isGood || folder.clauses.some(id => found.has(id))) {
              callStack.forEach(id => badFolders.delete(id))
              if (isGood)
                folder.clauses.forEach(id => found.add(id))
            }
        }, [] ) )
      expandAll(true)
      setAll(false, badFolders, new RegExp(pattern, "gi"), found)
      return
    }
    setAll(true, null, null, null)
  }

  const clearFindFilter = (e) => {
    setAll(false, null, null, null)
    refFind.current.textContent = ''
  }
  
  //const reverse = /<span data-x="1">([^<]*)<\/span>/gi
  const drawFind = s => srchPattern ? 
    s.replace(srchPattern, '<span data-x="1">$&</span>') 
    : s

  const radioElement = (name, text) =>
    (<div key={name} 
       onClick={()=> {
        setViewVars(name)
        checkFindFilter()
         }
       }
       data-current={name === viewVars} >
       {text}</div>);

  const traverseFolders = (folder, func, callStack) => {
    const locStack = callStack ? [...callStack, folder.id] : null
    func(folder, locStack)
    if (folder.subs)
      folder.subs.forEach(f => traverseFolders(f, func, locStack))
  }

  const radioElement1 = (name, text) =>
  (<div key={name} 
   onClick={()=> {
    setCollapse(name)
    expandAll(name !== 'collapsed')    
   } }
   data-current={name === collapsed} >
   {text}</div>);

  const showCreator = id => !id  ? ''
    : id === user._id ? user.firstname + ' ' + user.lastname
      : knownUsers[id] ? knownUsers[id].firstname + ' ' + knownUsers[id].lastname : ''

  const showClause = (id, fid, lvl) => {
    // [{id:10, title: 'first clause', hint: 'its hint', content: 'content of it',
    //   addedBy: 1231324, addedAt: 23423424, isDraft: true]
    const clause = clauseLib.clauses.find(x => x.id === id), 
    check = checkFuncs[viewVars]
    if (!clause) return false
    if (!check(clause) || founded && !founded.has(clause.id))
      return false
    return <div key={''+fid+id}
      style={{paddingLeft: (40*lvl)+"px"}}> 
      <div styleName='line' >
      <svg width="11" height="13" viewBox="0 0 11 13"
         dangerouslySetInnerHTML={{ __html: `<path fill="none" fill-rule="evenodd" stroke="#ACACAC" d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />`}}>
      </svg>
      <div styleName='tab-cat' style={{width: (320-lvl*40) + "px"}}
        className='pointable'
        onClick={e => setEditClause({kind:'clause', clause, 
          y: e.target.offsetTop})} >
        <div dangerouslySetInnerHTML={{ __html:drawFind(clause.title)}}></div>
        <div styleName='hint' dangerouslySetInnerHTML={{ __html:drawFind(clause.hint)}}></div>
      </div>
      <div styleName='tab-clause' className='pointable'
        onClick={e => setEditClause({kind:'clause', clause, 
          y: e.target.offsetTop})} 
          dangerouslySetInnerHTML={{ __html:drawFind(clause.content)}}
       >
      </div>
      <div styleName={`tab-status${clause.isDraft ? " draft" : ""}`}>
        {clause.isDraft ? transS('Draft') : transS('Published')}
      </div>
      <div styleName="tab-status">
        {/* statistics */}
      </div>
      <div styleName="tab-status">
        {clause.when ? getDateString(clause.when, 1, dateData) : ''}
      </div>
      <div styleName="tab-status">
        {showCreator(clause.edited)}
      </div>
    </div></div>
  }

  const modifyClause = err => {
    const kind = makeNew, clause = editClause
    setEditClause(null)
    setMakeNew(null)
    if (err) return
  }
  
  return <>
    <ListHeader currentOption={3} user={user} />
    <div styleName="wrapper">
    <div className='h1'>{transS("Clause Library")}</div>
    <div styleName='buttons'>
      <Button text={transS('New Clause')}
        mod='blue fill' 
        callback={newClause}/>
      <Button text={transS('New Folder ')}
        mod='blue arch' 
        callback={newFolder}/>
      <div styleName="head_radio">
        {radioElement1("collapsed", transS("Collapse All"))}
        {radioElement1("expanded", transS('Expand All'))}
      </div>
      <div styleName="head_radio">
        {radioElement("All", transS("All"))}
        {radioElement("Published", transS('Published '))}
        {radioElement("Drafts", transS('Drafts'))}
      </div>
      <div styleName='pdOptions'>{transS('Find:')}</div>
      <div 
        styleName={`pdOptions dFind${findNotFound ? ' nfound' :''}`}
        ref={refFind}
        onInput={e => checkFindFilter(e.target)}
        contentEditable="true">        
      </div>
      <span styleName='crossPlace' onClick={e => clearFindFilter(e)}>
        <span className='close-cross'>+</span>
      </span>
      
    </div>

    <div styleName='tab-headers line'>
      <div styleName='tab-cat'>{transS('Categories and Clauses')}</div>
      <div styleName='tab-clause'></div>
      <div styleName='tab-status'>{transS('Status')}</div>
      <div styleName='tab-status'>{transS('Statistics')}</div>
      <div styleName='tab-status'>{transS('Modified on')}</div>
      <div styleName='tab-status tab-last'>{transS('Modified by')}</div>
      {!!(makeNew || editClause) && 
      <Clause 
        kind={makeNew || editClause?.kind}
        clause={editClause?.clause}        
        callback={modifyClause}
        shift={editClause ? editClause.y : 0}
      />}
    </div>    

    {clauseLib.folders.length || clauseLib.clauses.length ? 
      <ShowCategories 
        clausesCallback={showClause}
        folders={clauseLib.folders}
        closedFolders={openedFolders}
        drawFind={drawFind}
        restricted={filteredFolders}
        setOpenFolders={s => { setCollapse(null);
          setOpenFolders(s)} }
        callback={(f, y) => f?.id === 1 ? null : 
          setEditClause({kind:'', clause: f, y})} />      
      : <div styleName='blank'>{transS('Insert folders and clauses')}</div>
    }       
        
  </div>   
  </>       
}

export default CSSModules(ClauseLib, styles, {allowMultiple: true});

