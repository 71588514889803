import {
    fileFormatValidation,
    fileSizeValidation
} from '../../../common/validation';
import { transS } from './lang';


/**
 * Prepare external user list for reducer
 *
 * @param {Array} list
 */
export const prepareExternalUserListForReducer = list => {
  return list.map(item => item.user);
};

/**
 * Reset pop up data in reducer
 */
export const popUpReset = () => ({
  name: '',
  text: '',
  confirm: {
    name: '',
    event: () => {}
  },
  cancel: {
    name: '',
    event: () => {}
  }
});

/**
 * Resets popUpWithInput data in reducer
 * @return {{popup: {name: string, text: string, inputText: string, confirm: {name: string, event: (function())}, cancel: {name: string, event: (function())}}}}
 */
export const popUpWithInputReset = () => {
  return {
    popup: {
      name: '',
      text: '',
      inputText: '',
      confirm: {
        name: '',
        event: () => {}
      },
      cancel: {
        name: '',
        event: () => {}
      }
    }
  };
};
export const hasSameUsers = (a, b) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i].length !== b[i].length) return false;
  }
  return true;
};

// export const extractQueryParams = queryString => {
//   const params = queryString.slice(queryString.search('?'));
//
// };

export const showDeletedProjectPopUpReset = () => {
  const popUp = {
    name: '',
    text: '',
    confirm: {
      name: '',
      event: () => {}
    }
  };
  return popUp;
};

export const showPopupManyFiles = (getPopUpAction, showPaymentAction) => {
    return {
      name: 'confirm',
      header: transS('Upgrade Plan'),
      swap: true,
      text: `In your Free Account you can add new documents until you have 5 in total.<br><br>Upgrade your account to add more documents.`,
      confirm: {
        name: 'Upgrade',
        event: () => {
            getPopUpAction(popUpReset);
            showPaymentAction(true)
        }
      },
      cancel: {
            name: transS('Cancel'),
            event: () => {
                getPopUpAction(popUpReset);
            }
      }
   };
}

export const universalDrop = (files, finCallback, lockNames=[]) =>  {
    let transferFiles = [], transferNames = [],
        fileCount = 999;
    if (!files.length) return;

    for (let f of files) {
        if (fileSizeValidation(f) || fileFormatValidation(f)
               || lockNames.includes(f.name) ) continue;

        let reader = new FileReader();
        reader.onloadend = () => {
            transferFiles.push(reader.result);
            transferNames.push(f.name);
            if (transferFiles.length === fileCount) {
                finCallback(transferFiles, transferNames);
            }
        };
        reader.readAsDataURL(f);
        fileCount++;
    }
    fileCount = fileCount - 999;

    }

    export const blockEvent = e => {
      e.stopPropagation()
      e.preventDefault()
    }

    export const getRelativePosition = (event, target) => {
      const 
        viewportWidth = window.innerWidth,
        viewportHeight = window.innerHeight,
        centered = {
          x: viewportWidth/2,
          y: viewportHeight/2
        }
      if (!event) return {centered}
      const {clientX, clientY} = event,
      {top, left} = target.getBoundingClientRect(),
      x = clientX-left, y =  clientY - top
      centered.x -= left
      centered.y -= top
      return {x, y, position: {x,y}, centered}
    }