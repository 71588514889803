import React, { useState, useRef } from "react";
import { colors, fontSize } from "./colors";
import { ButtonStd } from "./button/Buttons";
import { Icon_Doc, Icon_x } from "./svglib/svglib";
import { transS } from "../services/helpers/lang";

export const StdDrop = ({ onFilesChange }) => {
  const [files, setFiles] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Индекс редактируемого файла
  const fileInputRef = useRef(null);

  const handleAddFiles = (newFiles) => {
    const updatedFiles = [
      ...files,
      ...Array.from(newFiles).map((file) => ({ file, name: file.name })),
    ];
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) handleAddFiles(selectedFiles);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) handleAddFiles(droppedFiles);
  };

  const handleNameChange = (index, newName) => {
    const updatedFiles = [...files];
    const { file } = updatedFiles[index];
    const extension = file.name.split('.').pop(); // Получение расширения файла

    updatedFiles[index].name = newName.includes(`.${extension}`)
      ? newName // Если пользователь уже добавил расширение вручную
      : `${newName}.${extension}`; // Добавление расширения обратно
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const handleStartEditing = (index) => {
    setEditingIndex(index);
  };

  const handleStopEditing = () => {
    setEditingIndex(null);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const addDocStyle = {
    backgroundColor: colors.white,
    color: colors.primary,
    borderColor: colors.white
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: 320,
        position: "relative",
        border: `1px dashed ${colors.primary}`,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        boxSizing: "border-box",
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleFileDrop}
    >
      <ButtonStd style={addDocStyle} Picture={() => 
        <Icon_Doc color={colors.primary} />} 
        action={handleClick} text={transS('Add Documents')} />
      <input
        type="file"
        multiple
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
      {files.length > 0 && (
        <>
          <p style={{ marginTop: 10, color: colors.lightGray }}>
            {transS('You can edit document names')}
          </p>
          <ul style={{ listStyle: "none", padding: 0, width: "100%" }}>
            {files.map((file, index) => {
              const isEditing = index === editingIndex;
              const { name } = file;
              const extension = name.split('.').pop();
              const baseName = name.slice(0, -(extension.length + 1)); // Имя без расширения

              return (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0",
                    gap: 5,
                  }}
                >
                  {isEditing ? (
                    <input
                      type="text"
                      defaultValue={baseName}
                      onBlur={(e) => {
                        handleNameChange(index, e.target.value);
                        handleStopEditing();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNameChange(index, e.target.value);
                          handleStopEditing();
                        }
                      }}
                      autoFocus
                      style={{
                        flex: 1,
                        border: "none",
                        background: "transparent",
                        fontSize: fontSize.standart,
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <span
                      onClick={() => handleStartEditing(index)}
                      style={{
                        flex: 1,
                        cursor: "pointer",
                        fontSize: fontSize.standart,
                        marginRight: "10px",
                      }}
                    >
                      {name}
                    </span>
                  )}
                  <span
                    onClick={() => handleRemoveFile(index)}>
                    <Icon_x />
                  </span>                  
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

