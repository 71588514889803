import React from 'react'
import { useLocation } from "react-router-dom"
import { transS } from '../../../../services/helpers/lang'
import { colors, fontSize } from '../../../../components/colors'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

const styleContainer = {
    display: 'flex',
    gap: 40
  },
  styleElement = {
    fontSize: fontSize.readable,
    color: colors.darkGray,
    paddingBottom: 15,
    cursor: 'pointer',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    borderBottom: 'solid 5px',
    borderColor: 'transparent'
  },
  styleActive = {
    borderColor: colors.lightGray2,
    cursor: 'default'
  },
  styleLine = {
    width: 'calc(100% + 80px)',
    zIndex: 8,
    height: 1,
    backgroundColor: colors.lightGray2,
    position: 'absolute',
    top: 'calc(100% + 4px)',
    left: -40,
  }

export const TopMain = () => {
  const mainDictionary = [
    [transS('Documents'), '/units', 'unit'],
    [transS('Templates'), '/templates', 'template'],
    [transS('Clause Library'), '/clauses', 'clause'],
    [transS('Policies'), '/policies', 'policy'],
    [transS('Company Administration'), '/company', 'company'],
    [transS('Projects'), '/', ''],
   ],
   {pathname} = useLocation(),
   {user} = useSelector(state => state.userReducer),
   isCompanyAdmin = user.company?.knownCompanies[0].members._general.members.find(
      m => user._id === m._id)?.accessStatus > 0,
   active = mainDictionary.find(el => pathname.includes(el[2]))

  return <div style={styleContainer}>
    {mainDictionary.map((el,no) =>
      (!isCompanyAdmin && el[2] === 'company') ? false :
    <NavLink 
      style={el === active ? {...styleElement, ...styleActive} : styleElement}  
      key={no}
      to={el === active ? '#' : el[1]}>
        {el[0]}
    </NavLink>)}
  </div>
}

export const TopMainLine = () => <div style={styleLine}></div>