import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { findUnitTeams, findUserTeams } from './unitsFuncs';
import { transS } from '../../../../services/helpers/lang';
import { rights } from '../../../../../configs/appConfig';
import { Team } from './Team';
import { getRelativePosition } from '../../../../services/helpers';
import { UniPopup } from '../../../../components/popupWindows/UniPopup';

export const SelectTeams = ({unvisible, unit, action}) => {
  // action - signals that unit chnged
  // {adds: [], removes: [], changes:[]
  //    wheres elements {id: , email: , right: , toInvite:}} - adds
  //    id - removes
  //     {id, orig, newRight} - checnges
  const {unitsList, company, companyTree, unitsTree, knownUsers, luserId} = 
    useSelector(state => state.unitReducer)

  const [props, setProps] = useState(null),
    [showAddDialog, setShowAddDialog] = useState(false)
  let result, found

  useEffect(() => {
    setProps(findUnitTeams(unit, u => unitsList.get(u), 
     findUserTeams(luserId, companyTree)))
  }, [unit])

  
  const onTeamChange = (external, command, arg, right, isUser) => {
    // add, remove, right, title
    const thisTeam = external ? props.externalTeam : props.internalTeam,
      thatTeam = external ? props.internalTeam : props.externalTeam,
      userId = arg, 
      foundIdx = (command === 'remove' || command === 'right') ?
        thisTeam.findIndex(el => el.id === userId) : -1
    
    result = props.result
    switch (command) {
      case 'title':
        result.title = arg.trim()
        break
      case 'add':
        // added list of users and mails
        arg.forEach(newUser => {
          let teamMember, id = newUser.userid || newUser.loc_id, 
            newRight = (external ? external : rights.INTERNAL)
          if (props.deleted) {
            found = props.deleted.findIndex(el => el.id === id)
            if (found !== -1) {
              teamMember = props.deleted.splice(found, 1)[0]              
            }
          }
          if (!teamMember || !(teamMember & newRight)) {            
            newRight += rights.REP
            teamMember = {id,
              right: newRight, 
              justAdded: newUser,
              directRight: newRight}
          }
          thisTeam.unshift(teamMember)
          props.teams.unshift(teamMember)
        });
        break
      case 'remove':
        const deletedMember = thisTeam.splice(foundIdx, 1)
        props.teams = props.teams.filter(el => el.id !== userId)
        if (!props.deleted)
          props.deleted = deletedMember
        else
          props.deleted.push(deletedMember[0])
        break
      case 'right':
        const dR = thisTeam[foundIdx].directRight,
          tR = thisTeam[foundIdx].right,
          changedRight = (dR & right) ? -right
          : ( !(tR & right) ) ? right : 0
        if (!changedRight)
          return  // changed nothing
        thisTeam[foundIdx].directRight = dR + changedRight
        thisTeam[foundIdx].isUser = isUser
        thisTeam[foundIdx].right = tR + changedRight
        thisTeam[foundIdx] = {...thisTeam[foundIdx]}        
        break 
    }
    if (external) setProps({...props, externalTeam: [...thisTeam]})
    else setProps({...props, internalTeam: [...thisTeam]})
    action(recalcResult(result))
  }

  const recalcResult = result => {
    const adds = [], changes = [], ids = [],
      memberFind = member => {        
        const idx = unit.members ? unit.members.findIndex(el => el === member.id) : -1
        if (idx === -1) {
          if (!member.justAdded) {
            if (!member.directRight)
              return
            const isUser = member.isUser
            adds.push({userid: isUser, 
              loc_id: isUser ? null : member.id, 
              email: '', right: member.directRight})
          } else {
            const {userid, loc_id, email} = member.justAdded, right = member.directRight
            adds.push({userid, loc_id, email, right})
          }
        } else {
          ids.push(member.id)
          if (unit.rights[idx] !== member.directRight)
            changes.push({id: member.id, right: member.directRight})
        }
      }
    props.internalTeam.forEach(memberFind)
    props.externalTeam.forEach(memberFind)
    const removes = unit.members ? unit.members.filter(id => !ids.includes(id)) : []
    return !result.title && (adds.length + changes.length 
      + removes.length === 0) ? null 
      : {title: result.title, adds, changes, removes}
  }
  
  const styleCont = {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  }

  return (unvisible || !props) ? false : <div style={styleCont}>
    <div className='h2'>{transS('Companies & Teams')}</div>
    {showAddDialog && <UniPopup {...showAddDialog} />}
    <Team
      external={props.inInternal ? rights.EXTERNAL0 : 0}
      fashion="external"
      title={unit.externalTitle ? unit.externalTitle : transS("Unspecified Companies")}
      titleEditable={props.onTopRights && (props.internalEditor || props.externalEditor)}
      canAdd={props.onTopRights && props.internalEditor}
      memberList={props.inInternal ? props.externalTeam : []}
      alter = {!props.inInternal ? props.externalTeam : props.internalTeam}
      action={onTeamChange}
    />
    <Team 
      external={props.inInternal ? 0 : rights.EXTERNAL0}
      title={transS("Internal Team")}
      canAdd={props.onTopRights && (props.internalEditor || props.externalEditor)}
      canApprove={props.internalEditor}
      memberList={!props.inInternal ? props.externalTeam : props.internalTeam}
      alter = {props.inInternal ? props.externalTeam : props.internalTeam}
      action={onTeamChange}
      company={company}
    />
  </div>
}