import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setDisplayOnlyAction,
} from '../../../../redux/document/actions';
import { setFrameAction } from '../../../../services/actions/paragraphActions';
import {turnCommunicationVis} from '../../../../redux/paragraph/actions'
import Spinner from '../../../../components/spinner/Spinner';
import { colors, fontSize, minWithSize } from '../../../../components/colors';
import { unitConstants } from '../../../../services/reducers/unitReducer';


export const ViewBinDoc = props => {
  const dispatch = useDispatch();
    
  const checkStart = () => {    
    setDisplayOnlyAction(false, '');
    dispatch(turnCommunicationVis(1))
  };
  
  const spinner = () => 
    <div style={styleSpinner}><Spinner /></div>;
            
  const dispNone = () => 
    <div>Document content can't be converted to pdf. You can download it</div>

  const 
  styleSpinner = {
    position: 'fixed', 
    width: '100%', 
    height: '100%', 
    top: 0, 
    left: 0, 
    background: colors.white
  },
  styleViewImage = {
    overflow: 'auto',
    maxHeight: '100%'
  }, styleViewImageSpan = {display: 'inline-block'},
  styleSizeControl = {
    position: 'absolute',
    marginLeft: 20,
    width: 90,
    top: 0,
    left: '80rem',
    textAlign: 'center',
    cursor: 'pointer',
    color: colors.gray,
    fontSize: fontSize.h3,
    fontWeight: 'bold',  
    zIndex:15,
    display: 'flex',
    alignItems: 'center',
  }, styleResize = { flexGrow: 1 },
  stylePercent =  { flexGrow: 1, 
    fontWeight: 'normal',
    fontSize: fontSize.standart,
    margin: '5px 0', },
  styleWrapper = {
    minWidth: minWithSize,
    position: 'relative'
  },
  styleViewFrame = {
    width: '80rem',
    height: 'calc(100vh - 120px)'
  }

  const dispImage = () => 
    <>
    <div style={styleViewImage}>
      <span style={styleViewImageSpan}>
         <img src={`/api/unit/docs/getpure/${unitId}`}
           style={{width: imageScale+'%'}}
           onLoad={frameIsReady} />
       </span>
    </div>
    <div style={styleSizeControl}>
        <div style={styleResize} onClick={()=>resize(false)}>-</div>
        <div style={stylePercent} onClick={()=>resize(null)}>{imageScale}%</div>
        <div style={styleResize} onClick={()=>resize(true)}>+</div>
    </div>
    </>
    
  const resize = arg => {
    let newScale;
    if (arg === null) newScale = 100;
    else if (arg && imageScale < 990) newScale = imageScale + 10;
    else if (!arg && imageScale > 10) newScale = imageScale - 10;
    if (newScale && imageScale !== newScale) setImageScale(newScale);
  };
  
  const dispFrame = () => 
    <iframe name='iframeV' src={`/api/unit/docs/${selector === 'pdf' ? 'getpure' : 'getcontent'}/${unitId}/${docName}`}
          width="100%" height="100%"
          frameBorder="0" marginHeight="0" marginWidth="0"
          onLoad={frameIsReady} />
            
  const frameIsReady = (x) => {
      setContentReady(true);
      dispatch( setFrameAction(()=>null, {toggleBar: null}) );
  };
   
  useEffect(() => checkStart(), []);
  useEffect(() => {
    dispatch({ type: unitConstants.DOCUMENT, data: unitId})
    return () => dispatch({ type: unitConstants.DOCUMENT, data: null})
  })
  
  const { unitId, selector, docName } = props.match.params;
  const [nonEmptyContent, setContentReady] = useState(selector === 'none');
  const [imageScale, setImageScale] = useState(100);
 
  return ( //
    <div style={styleWrapper}> 
      <div style={styleViewFrame}>
         { selector === 'none' ? dispNone() : 
           selector === 'img' ? dispImage() : dispFrame()}
      </div>
      { !nonEmptyContent && spinner()}
    </div>
  );
};
