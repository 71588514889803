import { rights, socketConstants } from "../../../configs/appConfig";
import { transS } from "../helpers/lang";

const initialState = {
    unitsList: new Map(), 
    unitsTree: [],
    unitsStruc: null,
    knownUsers: new Map(),
    company: null,
    companyTree: null,
    companyStruc: null,
    currentUnit: null,
    loaded: null,
    luserId: null,
    unreadMap: new Map(), 
    isDocument: null,   
  };

export default (state = initialState, action) => {
  switch (action.type) {
  case unitConstants.SIMPLE:
    return {
      ...state,
      ...action.data,
    };
  case unitConstants.DOCUMENT:
    const isDocument = action.data ?
      state.unitsList.get(action.data) : null
      return {
        ...state,
        isDocument,
      };
  case unitConstants.TREE: 
    return {
      ...state,
      unitsTree: action.unitsTree
    } 
  case unitConstants.KNOWN_USER:
    // 
    const newMap = new Map(state.knownUsers)
    newMap.set(action.user.userid, action.user)
    return {
      ...state,
      knownUsers: newMap
    };
  case socketConstants.S_GATHER_ALL:
    //!!!! remove !!! to debug only
    // - removed - if (state.unitsStruc) return state 
    return gatherInitial(action.data, state)
  case socketConstants.S_CHANGES_UNREAD:
    return changesUnread(action.data, state)
  case socketConstants.S_UNIT_LEVEL:
    return {
      ...state,
      unitsStruc: {...state.unitsStruc, maxLevel: action.level}
    }
  default:
    return state
  }

}

export const unitConstants = {
  SIMPLE: 'SIMPLE',
  KNOWN_USER: 'KNOWN_USER',
  DOCUMENT: 'DOCUMENT',
  TREE: 'TREE',
}

const changesUnread = (data, state) => {
  const unreadMap = new Map(data.map(item => ([item.loc_id, item])))
  return {
    ... state,
    unreadMap
  }
}

const gatherInitial = (data, state) => {
  // data is object with    
      // "company": "025fc7bf1aff54ac7e818b1e10",
      // "tree": [ -- company tree
      //     {
      //         "loc_id": "021728739262103",
      //         "ancestor": "025fc7bf1aff54ac7e818b1e10",
      //         "folders": [
      //             null                        -- team ids
      //         ],
      //         "members": [
      //             "025b716fed6003183c2640af97" --user ids
      //         ],
      //         "rights": [
      //             5                            -- rights simmetric
      //         ],
      //         "team_name": "R&D",             -- special _deactivated _invited
      //         "team_type": 0,                   -- 1 is company
      //         "props": null                     -- for company
      //     },
      //     ... sample props for company          
      //         "props": {
      //             "address": {
      //                 "zip": "248000",
      //                 "state": "",
      //                 "country": "Russia",
      //                 "address1": "Suvotova St., 46",
      //                 "address2": ""
      //             }          
      // ],
      // "companyUsers": [
      //     {
      //         "userid": "025b716fed6003183c2640af97",
      //         "firstname": "Theodor",
      //         "lastname": "Oberländer",
      //         "email": "wldmr@everest.kaluga.ru",
      //         "isactivated": true,
      //         "avatar": "/avatars/863c10b02d260fe98ff94027c1a466d21605475591283.png"
      //     }
      //     ...
      // ],
      // "units": [  -- sorted by abcestors and created
      //     {
      //         "ancestor": null,
      //         "loc_id": "0263a54902cb4d2929c489c5c6",
      //         "title": "New Project 5",
      //         "ucreated": "1671776514896",
      //         "description": null,
      //         "integrated": 18080, -- rights of current user
      //         "version": null,
      //         "created": null,
      //         "orig": null,
      //         "editor": null,
      //         "editorsgroup": null,
      //         "members": [  -- teams and users
      //             "02651067c068d21c2e3fddf10b", 
      //             "025b716fed6003183c2640af97",
      //             "025db2ced7c7b3c72531cc7732",
      //             "025bf9079d6003183c2640afd0",
      //             "025bf6aa796003183c2640afce"
      //         ],
      //         "rights": [   -- of teams and users (integrated)
      //             17920,
      //             18080,
      //             17408,
      //             149504,
      //             149504
      //         ],
      //         "shared": null,
      //         "approved": null
      //     },  
      //     ...  
      // ],
      // "knownUsers": [
      //     {
      //         "userid": "025b6d7e076003183c2640af90",
      //         "firstname": "Peter",
      //         "lastname": "Andreev",
      //         "email": "peter@everest.kaluga.ru",
      //         "isactivated": true,
      //         "avatar": "/avatars/887daf494194db58b9d22dc4983d01231593850629630.jpg"
      //     },
      //     ...
      // ]

    // knownUsers appended with company Users with property incompany
    const {company, companyTree, unitsTree, changesUnread, 
      luserId, userParams } = data,
    {openFolders, pinned, externals} = userParams,
    pinnedFolder = {ancestor: null, mark: 'p', title: transS('Pinned documents'),
      level: 0, pinnedFolder: 1, loc_id: '_pinned', 
      folders: []},
    externalsFolder = {ancestor: null, title: transS('External documents'),
      level: 0, loc_id: '_externals', externals: 1,
      folders: []}

    unitsTree.unshift(pinnedFolder, externalsFolder)

    const common = company ? 
        [...data.knownUsers, ...data.companyUsers] : data.knownUsers,      
      knownUsers = new Map(common.map(item => [item.userid, item])),
      unitsList = new Map(unitsTree.map(item => [item.loc_id, item])),
      unreadMap = new Map(changesUnread.map(item => ([item.loc_id, item]))),
      putExternals = el => {
        el.ancestor = '_externals'
        externalsFolder.folders.push(el.loc_id)
        el.movable = 1
      }   

    unitsTree.forEach((el, i) => {
      if (!el.folders) el.folders = []      
      if (!el.ancestor) 
        return el.is_internal || i < 2
          ? null : putExternals(el)
      const parent = unitsList.get(el.ancestor)
      if (!parent) return putExternals(el)
      if (!parent.folders) parent.folders = [el.loc_id]
      else parent.folders.push(el.loc_id)
    });
    
    let splicer = 0
    if (externals) {
      externals.forEach(fold => {
        unitsTree.push(fold)
        unitsList.set(fold.loc_id, fold)
      })
      externals.forEach(fold => {
        let hasBlanks
        if (fold.ancestor === '_externals')
          externalsFolder.folders.splice(splicer++, 0, fold.loc_id)
        fold.folders.forEach((f, i, a) => {
          const u = unitsList.get(f)
          if (u && u.ancestor === fold.loc_id)
            return      
          if (u && u.ancestor === '_externals') {
            const eidx = externalsFolder.folders.findIndex(f => f === u.loc_id)
            if (eidx !== -1)
              externalsFolder.folders.splice(eidx, 1)
            u.ancestor = fold.loc_id
          } else {
            a[i] = null
            hasBlanks = true
          }
      })
      if (hasBlanks)
        fold.folders = fold.folders.filter(el => !!el)
      })
    }

    const calcApprovals = (unit, prev) => {
      if (!unit.rights) {
        unit.approvalsSet = prev
        return prev
      }
      let approvals = prev ? prev  : new Set()

      unit.rights.forEach((r,i) => {
        if (r & rights.APPROVE)  approvals.add(unit.members[i]) })
      unit.approvalsSet = approvals
      return approvals
    }
    
    const unitsStruc = prepareTree(unitsTree, state.unitsStruc, 1, calcApprovals),
      companyStruc = prepareTree(companyTree,  state.companyStruc, 3)

    if (openFolders)
      unitsStruc.openFolders = new Set(openFolders.filter(
        el=>unitsList.get(el) ) )
    if (pinned)
      pinnedFolder.folders = pinned.filter( el=>unitsList.get(el) )

    return {
      ... state,
      luserId,
      unitsList,
      unitsTree,
      unitsStruc,
      knownUsers,
      company,
      companyTree,
      companyStruc,
      unreadMap,
      loaded: true
    }
}

const prepareTree = (tree, struc, openLevels = 1, traverse) => {
  const calcLevel = (parent, level = 0, param = null) => {
    const elements = tree.filter(t => t.ancestor === parent)
    let maxLevel = level
    elements.forEach(e => {
      const res = traverse ? traverse(e, param) : param      
      e.level = level
      if (!e.folders.length) return
      const calced = calcLevel(e.loc_id, level+1, res)
      if (calced > maxLevel) maxLevel = calced
    })
    return maxLevel
  }

  const maxLevel = calcLevel(null),
    openFolders = struc ? struc.openFolders : new Set(['_pinned', '_externals']),
    listCallback = struc ? struc.listCallback : null
  // to do get folders and pinned from user settings and check are ids available
  for(let i=0; i<openLevels; i++)
    tree.filter(t => t.ancestor === null || openFolders.has(t.ancestor))
      .forEach(s => openFolders.add(s.loc_id))  
  return {maxLevel, openFolders, listCallback}
}
