import { rights } from "../../../../../configs/appConfig";
import { getHistoryDocumentAction, setDocumentNavigationAction, setDocumentViewedSuccessAction, setFormatable, setUsedVars, setVarChange, setVarClick, showDateControl } from "../../../../redux/document/actions";
import { blockParagraphsAction, setParagraphStyles, turnCommunicationVis, turnFormattingAction, turnInsertModeAction } from "../../../../redux/paragraph/actions";
import { getPopUpAction } from "../../../../services/actions/dataActions";
import { setMenuAction, setPopupMode } from "../../../../services/actions/paragraphActions";
import { setNewClause } from "../../../../services/actions/userActions";
import api from "../../../../services/api/api";
import { popUpReset } from "../../../../services/helpers";
import { transS } from "../../../../services/helpers/lang";
import { getDateData, getStoreHeap, userInfofromId } from "../../../../services/store/storeHelper";
import { helperFrameEvents } from "./frameDocHelper";

let thisFrame = {isDocument: 0}
export const setFrameProps = params => {
    thisFrame = params
}
export const handleFrameTasks = (e) => {
  /*  const { match, paragraphList, projectList, currentProject, commentList, paragraphToFocus, user, actualDocument,  
      displayOnly, displayVersion, getActualDocumentAction, blockParagraphsAction, 
      turnCommunicationVis, turnInsertModeAction, turnFormattingAction, setJumpChanges,
      getHistoryDocumentAction, externalUserList, internalUserList, setMenuAction, stateMenu, unreadTasksAction,
        setDocumentNavigationAction, dateData, redos, setParagraphStyles } = this.props; */
    const {dispatch, popupMode} = thisFrame
    if (!e.data || (typeof(e.data) != "string") ) return;
    let msg = JSON.parse(e.data);
    if (msg.c1) {
      switch (msg.c1) {
      case 'frame': initFrame(); 
        helperFrameEvents(msg)
        break
      case 'height': notOnlyHeight(msg) 
        break
      case 'scroll':
        if (msg.offset) scrollMe(msg.offset)
        else if (msg.scrollTo) window.scrollTo(0, msg.scrollTo)
        else window.scrollBy(0, msg.offsetRel);
        break;
      case 'setmenu':
        dispatch(setMenuAction(msg.action))
        break;
      case 'jump':
        if (popupMode) dispatch(setPopupMode(false))
        if (msg.pos) window.scroll(0, msg.pos);
        else dispatch(getHistoryDocumentAction(msg.documentId, msg.historyDocumentId, msg.blocked, msg.version, msg.id))
        break;
      case 'unHideComments':
        dispatch(turnCommunicationVis(0))
        break;
      case 'clause': 
        dispatch(setNewClause(msg.quote, thisFrame.user._id, 
          msg.shift, () => dispatch(setNewClause(null))))
        break
      case 'jumpChanges':
        if (msg.pos) window.scrollTo({ // плавный скролл
            top: msg.pos,
            behavior: "smooth"
        })
        dispatch(setJumpChanges({end: msg.end, vis: msg.vis}))
        break
      case 'marks':
        //api.unreadMarks(actualDocument._id, msg.list)
        //this.props.calculateUnreadTasksAction(msg.list.split(' '))
        break;
      case 'unreadTasks':
        //unreadTasksAction(actualDocument.coreDocument, msg.commentId, this.props.user.userGroup)
        break
      case 'readpars':
                // let p = projectList.find(p => p._id === currentProject._id);
                // if (p && p.docs) {
                //     let d = p.docs.find(d => d.coreDocument === actualDocument.coreDocument);
                //     if (d && d.dochanges)
                //         msg.pars.forEach(no => {
                //             if (d.dochanges[no]) {
                //                 if (!user.docs) user.docs = {};
                //                 if (!user.docs[d.coreDocument]) user.docs[d.coreDocument] = {};
                //                 user.docs[d.coreDocument][no] = d.dochanges[no];
                //             }
                //         });
                // }
                // setMenuAction({changesCount: msg.remains});
                // api.touchDocument(actualDocument.coreDocument, {data: encodeURIComponent(e.data)});
                break;
      case 'checkpars':
        dispatch(setDocumentViewedSuccessAction({changesCount: msg.remains, hadChanges: 1}))
        break;
      case 'writepars':
          // if (!user.docs) user.docs = {};
          // if (!user.docs[actualDocument.coreDocument]) user.docs[actualDocument.coreDocument] = {};
          // msg.pars.forEach(no => {
          //     user.docs[actualDocument.coreDocument][no] = msg.time;
          // });
          break;
      case 'change':
        break
            console.log(e.data)
          api.chgDocument(actualDocument._id, {data: encodeURIComponent(e.data)}).then(res => {
              const data = res.data; //todo parse error
          })
              .catch(resp => {
                  getActualDocumentAction({
                      documentId: actualDocument.coreDocument,
                      projectId: actualDocument.projectId, historyDocumentId: null,
                      preventChangeRoute: true
                  });
              });
          break;
      case 'lock':
        break
          api.lockDocument(actualDocument._id, {data: encodeURIComponent(e.data)}).then(res => {
              const data = res.data; //todo parse error
              if (data.data && data.data.failed)
                  this.sendToFrame(data.data);
          })
              .catch(resp => {
                  getActualDocumentAction({
                      documentId: actualDocument.coreDocument,
                      projectId: actualDocument.projectId, historyDocumentId: null,
                      preventChangeRoute: true
                  });
              });
          break;
        case 'trap':
          api.trapDocument(thisFrame.docUnitId, {data: encodeURIComponent(e.data)});
          break;
        case 'toFormatPanel':
          dispatch(turnCommunicationVis(2)); break;
        case 'turnInsertMode':
          dispatch(turnInsertModeAction(msg.insertOn, msg.canPaste))
          break;
        case 'clearFormat':
          dispatch(turnFormattingAction(null)) 
          break;
        case 'setFormat':
          dispatch(turnFormattingAction(msg)); break;
        case 'setParagraphStyles':
          dispatch(setParagraphStyles(msg)); break;
        case 'overlineParams':
            thisFrame.setOverlineParams({overlineParams: msg}); break;
        case 'restart':
            // getActualDocumentAction({
            //             documentId: actualDocument.coreDocument,
            //             projectId: actualDocument.projectId, historyDocumentId: null,
            //             preventChangeRoute: true
            //         }); 
          break;
        case 'comment': processComment(msg)
          break
        case 'isDocChanged':
          getStoreHeap().waiting_isDocChanged = msg.value;
          break
        case 'navigation':
          dispatch(setDocumentNavigationAction(msg.current, msg.paras, msg.isJump, msg.isNeedBack))
          break
        case 'formatable': dispatch(setFormatable(msg.fonts)); break;
        case 'dateControl': 
          const {setDateVar, setVarList} = thisFrame
          setDateVar(msg.dateVar); setVarList(msg.varlist)
          dispatch(showDateControl(
            msg.left, msg.top, msg.dateVar, true)) 
          break;          
        case 'variable':
          if (msg.action === 'varChg') { 
            // api.updateTemplate(actualDocument.coreDocument, 'c1 send vars', 
            //   undefined, actualDocument._id, msg.variable);
            dispatch(setVarChange(msg.variable))
          } else if (msg.action === 'checked') {
            dispatch(setUsedVars(msg.usedVars, msg.unusedVars))
          } else if (msg.action === 'clicked') 
            dispatch(setVarClick(Date.now(), msg.id, msg.option, msg.y - window.scrollY))
          break;
        }
    }
}

const initFrame = () => {
  const {location, isDocument, dispatch, user, 
    sendToFrame, locks, redos} = thisFrame
  dispatch(blockParagraphsAction(true))
  const int = location.pathname.includes('/template/') ? [] 
    : isDocument.members.filter((_, i) => 
        isDocument.rights[i] & rights.INTERNAL).map(el => ({
          id: el.split('_')[1], //loc
          info: userInfofromId(el)})),
    ext = isDocument.members.filter((_, i) => 
      isDocument.rights[i] & rights.EXTERNAL).map(el => ({
        id: el.split('_')[1], //loc
        info: userInfofromId(el, 1)})),
    isCollaborative = isDocument.orig == 6,
    isDraftDoc = isDocument.orig === 302
    
    let created = '', new_history = ''
    /* history version
    if (displayVersion) {
        let el = actualDocument.history.find(el => {
            return el.version + "." + el.subVersion === displayVersion
        });
        if (el) created = el.createdAt;
        isCollaborative = false;
    }
    let new_history;
    if (!actualDocument.history)
        new_history = actualDocument.new_history + '\n'
            + JSON.stringify({
                editorId: actualDocument.editorId,
                version: actualDocument.version,
                subVersion: actualDocument.subVersion,
                orig: actualDocument.orig,
                timestamp: +moment(actualDocument.createdAt),
                editorsGroup: actualDocument.editorsGroup
            });
    else {
        new_history = actualDocument.history.reduce((acc, item) => {
            return `${acc}\n{"editorId":"${item.editor._id}","version":${item.version},"subVersion":${item.subVersion},"orig":${item.orig},"timestamp": ${+moment(item.createdAt)}, "editorsGroup": "${item.editorsGroup}"}`;
        }, '');
        if (new_history) new_history = new_history.slice(1);
    } */
    sendToFrame({
      c1: 'main',
      coreDocumentId: isDocument.loc_id,
      myId: user._id,
      lang: user.lang,
      myGroup: user.userGroup, //loc
      dateData: getDateData(),
      blocked: !isCollaborative && fakeReplace.blocked,
      isDraftDoc,    
      created: created,
      ext: ext,
      int: int,
      isProhibitPrivate: fakeReplace.isProhibitPrivate,
      permissionPrivate: fakeReplace.permissionPrivate,

      publicComments: fakeReplace.paragraphList,
      privateComments: fakeReplace.commentList,
      halfScreen: window.innerHeight * 0.4,
      locks,
      redos,
      new_history: fakeReplace.new_history,
      
      //dochanges: actualDocument.dochanges,
      //udochanges: user.docs && user.docs[actualDocument.coreDocument],
      //variables: actualDocument.variables && JSON.parse(actualDocument.variables),
      showedChanges: 0,
      //askChanges: actualDocument.version == 1,
      //jumpPoint: match.params.itemId
    });

    // this.setState({blankFrame: false});
    /*
    let setMenuParams
    if ((localStorage.getItem('paragraphStateMenu') || '').includes(actualDocument.coreDocument)) {
        setMenuParams = JSON.parse(localStorage.getItem('paragraphStateMenu'))[actualDocument.coreDocument]
    } else {
        setMenuParams = {showChanges: 0, radioBtn: 0}
    }
    if (actualDocument.orig === 9 || actualDocument.orig === 10 
      || actualDocument.orig === 12 || actualDocument.orig === 13 
      || actualDocument.orig === 14) {
        let currentProjectCreator = null
        projectList.forEach(project => (project._id === currentProject._id) ? currentProjectCreator = project.creator : null)
        const isExternal = !internalUserList.find(u => u._id === currentProjectCreator)
        if (actualDocument.orig === 10 && isExternal || actualDocument.orig === 9 && !isExternal)
            setMenuParams.showChanges = 3
        else if (actualDocument.orig === 10 && !isExternal || actualDocument.orig === 9 && isExternal)
            setMenuParams.showChanges = 3
        else if (actualDocument.orig === 12 || actualDocument.orig === 13 || actualDocument.orig === 14)
            setMenuParams.showChanges = 3
    }
    setMenuParams.undo = false; setMenuParams.redo = false; 
    setMenuAction(setMenuParams) */
   
}

const notOnlyHeight = (msg) => {
  const frame = frames['iframe'],
    {dispatch} = thisFrame
    if (!frame) return
    frame.height = msg.height;
    if (msg.rem) {
      const myRem = parseFloat(getComputedStyle(document.documentElement).fontSize)
      frame.document.documentElement.style.fontSize = `${myRem}px`
    }
    if (msg.pos) window.scroll(0, msg.pos);
    if (msg.init) {
      dispatch(blockParagraphsAction(false))
      /*
      api.unreadMarks(actualDocument._id, '').then(res => {
          if (!window.location.pathname.includes('/template/')) {
              this.props.calculateUnreadTasksAction(res.data.data.list.split(' '))
          }
          this.sendToFrame({
              c1: 'marks',
              list: res.data.data.list
          });
      }) */
    }
}

const scrollMe = offset => {
  const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, 
    document.documentElement.clientHeight, document.documentElement.scrollHeight, 
    document.documentElement.offsetHeight),
  windowHeight = document.documentElement.clientHeight || window.innerHeight 
    || document.getElementsByTagName('body')[0].clientHeight,
  destinationOffsetToScroll = Math.round(
    documentHeight - offset < windowHeight ? documentHeight - windowHeight : offset)
  window.scroll(0,destinationOffsetToScroll)
}

const processComment = msg => {
  const {dispatch} = thisFrame
  if (msg.isFreezedReplies) {
    dispatch(getPopUpAction({
        name: 'confirm',
        header: transS('You cannot resolve a thread with an unsent message'),
        text: transL('delMessages'),
        cancel: {
            name: transS('Got it'),
            mod: 'blue arch big',
            event: () => dispatch.getPopUpAction(popUpReset)
        }
    }))
  } else if (!msg.content && msg.isPrivate) {
    dispatch(getPopUpAction({
      name: 'confirm',
      text: transS('Are you sure, you want to delete the comment?'),
      confirm: {
          name: transS('Delete'),
          mod: 'red fill big',
          event: () => { /*
              api.sendComment({
                id: actualDocument.coreDocument, listen: actualDocument._id,
                content: msg.content, title: msg.title, para: msg.para, comment: msg.comment,
                isPrivate: msg.isPrivate, freeze: msg.freeze, activeTimestamp: msg.activeTimestamp
              })
              .then(res => {
                  const data = res.data;
              })
              .catch(resp => {
                  this.sendToFrame({
                      c1: 'problem',
                      userId: user._id,
                      content: msg.content,
                      time: Date.now(),
                      para: msg.para,
                      ocomment: msg.comment,
                      comment: msg.comment ? msg.comment : Date.now(),
                      isPrivate: msg.isPrivate,
                      problem: resp.data
                  });
              }); */
              dispatch(getPopUpAction(popUpReset))
          }
      },
      cancel: {
          name: transS('Cancel'),
          event: () => dispatch(getPopUpAction(popUpReset))
      }
    }))
  } else { /*
    api.sendComment({
        id: actualDocument.coreDocument,
        listen: actualDocument._id,
        content: msg.content,
        title: msg.title,
        para: msg.para,
        comment: msg.comment,
        quote: msg.quote,
        resolve: msg.resolve,
        isPrivate: msg.isPrivate,
        freeze: msg.freeze,
        activeTimestamp: msg.activeTimestamp,
        recipients: msg.recipients
    }).then(res => {
        const data = res.data;
    })
        .catch(resp => {
            this.sendToFrame({
                c1: 'problem',
                userId: user._id,
                content: msg.content,
                time: Date.now(),
                para: msg.para,
                ocomment: msg.comment,
                comment: msg.comment ? msg.comment : Date.now(),
                isPrivate: msg.isPrivate,
                problem: resp.data,
            });
        });*/
  }
}


const fakeReplace = {
  blocked: false, //(displayOnly || actualDocument.blocked || actualDocument.readonly)
  isProhibitPrivate: false, // (user.ticks <= 0) && !hasMemberInternalGroupPermission(),
  permissionPrivate: true, // hasMemberInternalGroupPermission()
  new_history: '',
  paragraphList: '',
  commentList: '',
}