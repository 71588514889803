import React,  { useState, useEffect, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { colors, fontSize } from "../colors"
import { popUpReset } from '../../services/helpers';
import { getPopUpAction } from '../../services/actions/dataActions';
import { Icon_x } from '../svglib/svglib';

export const UniPopup = (props) => {
// 
  const {position, centered, items, crossed, setState, extra, fixWidth} = props,
    closePopup = () => setState(null)
  
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, 
    scrollTop = window.pageYOffset || document.documentElement.scrollTop, 
    viewportWidth = window.innerWidth,
    viewportHeight = window.innerHeight,
    screenMiddle = viewportWidth / 2,
    onRightSide = position && (position.x > screenMiddle),
    calcWidth = fixWidth || (extra ? 1200 : 400),
    calcPad = position ? 20 : 40

  const positionStyle = position ? {
    position: 'absolute',
    top: position.y,
    left: onRightSide ? position.x - calcWidth : position.x,
    zIndex: 8,    
  } : {position: 'absolute',
    left: centered.x,
    top: centered.y,
    zIndex: 8,    
  },
  flexCont = {
    display: 'flex',
    fontSize: fontSize.standart,
    color: colors.black,
    width: calcWidth,
    boxShadow: colors.shadowMenu,
    backgroundColor: colors.white,
    position: 'absolute',
    left: 0,
    top: 0,
    transform: position ? 'none' : 'translate(max(-45vw, -50%), max(-45vh, -50%))'
  },
  styled = {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: fixWidth || 400,
    padding: calcPad,
  },
  crossedStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '5px'
  }  

  useEffect(() => {
    const scrollY = window.scrollY;
    return () => {
      window.scrollTo(0, scrollY);
    };
  }, []);
  
  return <>
    <div className='dev-screen' onMouseDown={closePopup}></div>
    <div style={positionStyle}>
      <div style={flexCont}>
        <div style={styled}>          
          {items.map((Element, keyNo) => <div key={keyNo}
            style={(!keyNo && crossed) ? crossedStyle : {}}>
              {!!Element &&
                (typeof(Element) === 'string' ?
                <div>{Element}</div>
                : <Element close={closePopup} />
              )}
          </div>)}
        </div>
        {!!extra && extra()}
        {!!crossed &&
          <span style={{cursor: 'pointer',
            position: 'absolute', right: calcPad, top: calcPad
            }} onClick={closePopup}>
            <Icon_x />
          </span>
        }
      </div>
    </div>
  </>
}

 