import React, {useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { Icon_ArrowDown, Icon_CheckBlank, Icon_Checked } from '../../../../components/svglib/svglib';
import { transS } from '../../../../services/helpers/lang';
import { TreeSimple } from '../../../../components/TreeNode';
import { ButtonStd } from '../../../../components/button/Buttons';
import { colors } from '../../../../components/colors';

let selectedTeams, openFolders, alreadySelected
export const AddCompanyTeams = ({memberList, cb}) => {
  const {companyTree, companyStruc} = 
      useSelector(state => state.unitReducer),
    refPopup=useRef(),
    list = companyTree.filter(u => (!u.ancestor)),        
    getChildren = unit => { const result =
      !unit.folders?.length ? [] :
      unit.folders[0] === null ? [] :
      unit.folders.map(x => companyTree.find(el => el.loc_id === x))
      return result
    },
    collectTeams = () => {
      if (!selectedTeams.size)
        return cb(false)
      const collected = []
      companyTree.forEach(team => {
        if (selectedTeams.has(team.loc_id))
          collected.push(team)
      })
      cb(collected)
    }

  openFolders = companyStruc.openFolders
  alreadySelected = new Set(memberList.map(m => m.id))
  selectedTeams = new Set()
  
  useEffect(() => {

    const clickOutsideHandler = (e) => {
      if (refPopup.current && (refPopup.current === e.target ||
        !refPopup.current.contains(e.target))) {
          e.stopPropagation()
          e.preventDefault()
          cb(false)      
        }
    }
    window.addEventListener('click', clickOutsideHandler)
    return () => {
        window.removeEventListener('click', clickOutsideHandler)
    }
  }, [])
  
  return <div className='dev-member' ref={refPopup}>
    <div>
    <div className='h2'>{transS('Add Teams')}</div>
      <div>
      {list.map(el => <TreeSimple
          key={el.loc_id}
          unit={el}
          ShowElement={ShowListElement}
          getChildren={getChildren}
        />)}
      </div>
      <div style={buttonsStyle}>
        <ButtonStd text={transS('Done')} 
          action={collectTeams}
        />
        <ButtonStd text={transS('Cancel')} 
          style={{
            backgroundColor: colors.white,
            color: colors.primary,
          }}
          action={() => cb(false)}
        />
      </div>      
    </div>
  </div>
}

const styleLine = {
  display: 'flex',
  gap: 20,
  marginTop: 20,
}, buttonsStyle = {
  display: 'flex',
  alignSelf: 'start',
  gap: 20
}


const icons = {
  arrow_down: <Icon_ArrowDown />,
  arrow_up: <Icon_ArrowDown flip="1" />,  
}


const ShowListElement = ({unit}) =>  {
  const team = unit,
    hasChildren = !(!team.folders?.length || !team.folders[0]),
    unitRef = useRef()

  const toggleArrow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (unitRef.current.dataset.closed != 1)     
        unitRef.current.dataset.closed = "1"
    else 
        unitRef.current.dataset.closed = ""
  }

  const selectItem = team => {
    if (selectedTeams.has(team.loc_id)) {
      selectedTeams.delete(team.loc_id)
      unitRef.current.dataset.filterset = "0"
    } else if (!alreadySelected.has(team.loc_id)) {
      selectedTeams.add(team.loc_id)
      unitRef.current.dataset.filterset = "1"
    }
  }

  
  return <div style={styleLine} 
    id={"sel"+team.loc_id} ref={unitRef}
    data-closed={openFolders.has(team.loc_id) ? "" : "1"}
    data-filterset={selectedTeams.has(team.loc_id) ||
      alreadySelected.has(team.loc_id) ? "1" : "0"}
    onClick={() => selectItem(team)} >
      {!!unit.level && <span style={{width:unit.level*40 - 20}}></span> }
      <span>
        <span data-check="1"><Icon_Checked 
          color={alreadySelected.has(team.loc_id) ? colors.gray : colors.primary}/>
        </span>
        <span data-check="0"><Icon_CheckBlank /></span>
      </span>
      <span>{team.team_name}</span>
      {hasChildren 
        && <div 
          onClick={ e => toggleArrow(e)}          
        >
          <span data-on-open="1">{icons.arrow_up}</span>
          <span data-on-close="1">{icons.arrow_down}</span>
    </div>}
  </div>
}