import React, { useState } from 'react'
import { useLocation } from "react-router-dom"
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import ProgressAndTasks from "../../../progressAndTasks/ProgressAndTasks";
import ScrollBar from '../../../../../../components/scrollBar/ScrollBar'
import { colors } from '../../../../../../components/colors'
import Logo from '../../../../../../components/logo/Logo'
import Account from '../../../accountComponent/accountComponent'
import ScrollBlock from './ScrollBlock'
import { LeftNavigation } from './LeftNavigation'
import { TeamMember } from '../../../../views/unitList/TeamMember'
import { transS } from '../../../../../../services/helpers/lang'

export const LeftMenu = ({sideBarOpenHandler}) => {
  const [showProgress, setShowProgress] = useState(false),
    [showAccount, setShowAccount] = useState(false),
    {isDocument, unitsStruc, luserId} = useSelector(state => state.unitReducer),
    {user} = useSelector(state => state.userReducer)
  const marginLeft = showProgress ? 0 : -800,
    styleProgress = {
      marginLeft, 
      position: 'absolute',
      backgroundColor: colors.white,
      left: 400,
      top: 0,
      width: 'auto',
      minWidth: 801,
      height: '100%',
      transition: 'all .5s'
    }

return (
  <div style={styleContainer}>
    <ScrollBar style={styleScroll}>
      <div style={styleSideBar}>
        <div style={styleLogoWrapper}>
          <div className='scaffold'></div>
          <div style={styleLogoplace}>  <Logo />  </div>
          <img style={styleBurger} 
            src="../../../../../../images/burgerMenuOpen.png"
            onClick={() => sideBarOpenHandler(false)} alt='sidebar open'/>
        </div>
      </div>
      {isDocument && !showAccount ? <>
         {!isDocument && <ScrollBlock />}
         <LeftNavigation />
         <TeamMember luserId={luserId}
            clickable={true}
            callback={() => setShowAccount(true)} />
      </>
      : <Account user={user}/>}
            
    </ScrollBar>
    <div style={styleProgress}>
      {showProgress && <ProgressAndTasks
        versionToggleBar={() => setShowProgress(false)} // Progress & Tasks close handler
        toggleBar={sideBarOpenHandler} // SideBar close handler
    />}
    </div>
  </div>)
}
            
const styleContainer = {
  width: '100%', 
  height: '100%', 
  backgroundColor: colors.white, 
  position: 'relative', 
  padding: '40px 0'
}, styleScroll = {
  height: '100%',
  width: 'calc(100% - 8px)',
  zIndex: '10',
  backgroundColor: colors.white,
}, styleSideBar = {
  position: 'relative',
  padding: '86px 40px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
}, styleLogoWrapper = {
  position: 'absolute',
  top: -8,
  left: 40,
  height: 50,
  width: 'calc(100% - 40px)',
  display: 'flex',
  alignItems: 'baseline'
}, styleBurger = {
  marginLeft: 40,
  cursor: 'pointer',
  height: 15,
  width: 14,
},  styleLogoplace = {
  position: 'relative',
  top: 13,
  alignSelf: 'start',
}

