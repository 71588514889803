import store from ".";

export const userInfofromId = (id, ext) => {
  const {knownUsers} = store.getState().unitReducer,
    user = knownUsers?.get(id)
  const {loc_id, avatar, firstname, lastname, isActivated, email} = user || {}
  const _firstname = +item.email ? 'New User' : firstname,
    _email = +item.email ? '' : email
  return {id, loc_id, avatar, firstname: _firstname, 
    lastname, isActivated, email: _email, ext}
}

export const getDateData = () => store.getState().userReducer.dateData

export const getStoreHeap = () => store.getState().dataReducer.heap